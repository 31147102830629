<template>
  <section class="bg-gray-50 dark:bg-gray-900 pb-3  ">
    <div class="px-4 mx-auto max-w-screen-2xl lg:px-4">
      <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <section class="bg-gray-200 dark:bg-gray-900">
          <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2  lg:px-6">
            <div
              v-if="record != undefined"
              class="font-light text-gray-500 sm:text-lg dark:text-gray-400"
            >
              <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                {{ record?.product?.name }}
              </h2>
              <p class="mb-8 text-2xl">
                {{ record.product?.subname }}
              </p>
              <p class="mb-8 text-xl">
                Référence :
                {{ record?.product?.reference }}{{ record?.product?.internal_code }}
              </p>
              <div class="flex justify-center">
                <button
                  type="button"
                  class="hidden mt- flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  @click="onGenerateSheet"
                >
                  {{ common_labels['BUTTON_GENERATE_SHEET'][lg] }}
                  <i class="fa-solid fa-arrow-up-right-from-square ml-2" />
                </button>
              </div>
              <div
                class="w-full bg-gray-100  mt-2 border border-gray-300 rounded-lg pb-10 pl-3 pr-3 pt-3"
              >
                <div class="w-full text-lg text-gray-600 mb-1 font-semibold ">
                  Etat du Stock sur une production de {{ record && record.producedQuantity?.value }}
                  {{ record && record.producedQuantity?.unit }}
                </div>

                <div class="relative   mt-8">
                  <span
                    class="hidden text-sm text-gray-500 dark:text-gray-400 absolute start-0 -top-4 font-semibold"
                  >disponibles
                    : {{ record.quantity_stock }}</span>
                  <span
                    class="hidden text-sm text-gray-500 dark:text-gray-400 absolute end-0 -top-4 font-semibold"
                  >expédiés
                    : {{ record.quantity_expidited }}</span>

                  <label
                    for="labels-range-input"
                    class="sr-only"
                  >Labels range</label>
                  <input
                    id="labels-range-input"
                    type="range"
                    :value="`${record.quantity}`"
                    min="0"
                    max="120"
                    class="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  >
                  <span
                    class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6"
                  >0%</span>
                  <span
                    class="text-sm text-gray-500 dark:text-gray-400 absolute start-1/4 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6"
                  >25%</span>
                  <span
                    class="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6"
                  >50%</span>
                  <span
                    class="text-sm text-gray-500 dark:text-gray-400 absolute start-3/4 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6"
                  >75%</span>
                  <span
                    class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6"
                  >100%</span>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mt-2">
              <div class="flex flex-col text-left text-lg border-l-2 pl-3">
                <div
                  v-if="record && record.id"
                  class="flex flex-col"
                >
                  <div class="font-bold text-gray-800 mt-2 text-sm">
                    Numéro Lot :
                  </div>
                  <div class="font-normal text-gray-600  text-lg font-bold">
                    {{ record &&
                      record.id.toUpperCase() }}
                  </div>
                </div>
                <div
                  v-if="record && record.product && record.product.item_gtin_new"
                  class="flex flex-col"
                >
                  <div class="font-bold  text-gray-800 mt-2 text-sm">
                    GTIN/EAN :
                  </div>
                  <div class="font-normal  text-gray-600 text-lg ">
                    {{ record && record.product &&
                      record.product.item_gtin_new }}
                  </div>
                </div>
                <div
                  v-if="record && record.manufactured_at"
                  class="flex flex-col"
                >
                  <div class="font-bold text-gray-800 mt-2 text-sm">
                    Date Fabrication :
                  </div>
                  <div class="font-normal text-gray-600">
                    {{ formatDate(record.manufactured_at) }}
                  </div>
                </div>
                <div
                  v-if="record && record.expired_at"
                  class="flex flex-col"
                >
                  <div class="font-bold text-gray-800 mt-2 text-sm">
                    Date DLC /DDM :
                  </div>
                  <div class="font-normal text-gray-600">
                    {{ formatDate(record.expired_at) }}
                  </div>
                </div>
                <div
                  v-if="record && record?.producedQuantity"
                  class="flex flex-col"
                >
                  <div class="font-bold text-gray-800 mt-2 text-sm">
                    Quantité Produite :
                  </div>
                  <div class="font-normal text-gray-600">
                    {{ record && record?.producedQuantity?.value }}     {{ record && record?.producedQuantity?.unit }}
                  </div>
                </div>

                <div
                  v-if="record && record?.quantity_used"
                  class="flex flex-col  text-sm"
                >
                  <div class="font-bold text-gray-800 mt-2">
                    Quantité En Stock :
                  </div>
                  <div class="font-normal text-gray-600">
                    {{ record && record?.quantity_used }}   {{ record && record?.quantity_used }}
                  </div>
                </div>

                <div
                  v-if="record && record?.quantity_expidited"
                  class="flex flex-col  text-sm"
                >
                  <div class="font-bold text-gray-800 mt-2">
                    Quantité Expédiée :
                  </div>
                  <div class="font-normal text-gray-600">
                    {{ record && record?.quantity_expidited }}    {{ record && record?.unit }}  
                  </div>
                </div>
                <div
                  v-if="record && record?.quantity_used"
                  class="flex flex-col  text-sm"
                >
                  <div class="font-bold text-gray-800 mt-2">
                    Quantité Utilisée :
                  </div>
                  <div class="font-normal text-gray-600">
                    {{ record && record?.quantity_used }}   {{ record && record.quantity_used }}   
                  </div>
                </div>
              </div>
              <div
                class=" mt-1 w-full p-2 rounded-lg shadow-lg bg-white flex items-center justify-center "
                style="min-height:300px"
              >
                <img
                  :src="`https://files.b4food.io/b4food/images/${record?.product?.image_id}?icon=true`"
                >
              </div>
            </div>
          </div>
        </section>



        <div
          class="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4"
        >
          <div class="flex items-center flex-1 space-x-4">
            <h5>
              <span class="ml-1 dark:text-white font-bold"> Score Conformité </span>
            </h5>
            <div>
              <div class="flex items-center">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>First star</title>
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  />
                </svg>
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Second star</title>
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  />
                </svg>
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Third star</title>
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  />
                </svg>
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Fourth star</title>
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  />
                </svg>
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-yellow-300 dark:text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Fifth star</title>
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                  />
                </svg>
                <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                  4.95 out of 5
                </p>
              </div>
            </div>
          </div>
          <div
            class="flex  flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3"
          >
            <button
              type="button"
              class="hidden flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              <svg
                class="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                fill="none"
                viewbox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
              Update stocks 1/250
            </button>
            <button
              type="button"
              class="hidden flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              <svg
                class="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewbox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
              common_labels
            </button>
          </div>
        </div>

        <div
          class="min-h-500"
          style="min-height:400px"
        >
          <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
            <ul
              id="myTab"
              class="flex flex-wrap -mb-px text-sm font-medium text-center"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li
                v-for="(tab,index) in template.children"
                :key="tab.id"
                class="mr-2"
                role="presentation"
              >
                <button
                  :id="`tabs-${tab.id}`"
                  class="inline-block p-4 border-b-2 rounded-t-lg"
                  :data-tabs-target="`#tab-content-${tab.id}`"
                  type="button"
                  role="tab"
                  :aria-controls="`tabs-${tab.id}`"
                  :aria-selected="(index==0)"
                >
                  {{ tab.title[lg] }}
                </button>
              </li>
            </ul>
          </div>
          <div id="myTabContent">
            <div
              v-for="tab in template.children"
              :id="`tab-content-${tab.id}`"
              :key="tab.id"
              class="hidden p-0 rounded-lg bg-gray-100 dark:bg-gray-800"
              role="tabpanel"
              :aria-labelledby="`tabs-${tab.id}`"
            >
              <B4FContent
                v-for="body in tab.body"
                :id="`sheet-${tab.id}`"
                :ref="`sheet-${tab.id}`"
                :key="body.id"
                :config="body"
                :record="record"
                :settings="tab"
                @change-view="onChangeView"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    import common_labels from "@/assets/lg/common.json"
    import B4FContent from '@/components/ContentComponent.vue'

    export default {
        name: 'ResultComponent',
        components: {
            B4FContent
        },
        props: {
            config: Object,
            record: Object,
            template: Object,
            mode: String
        },
        data() {
            return {
                product_name: "",
                product_description: "",
                common_labels: common_labels,
                lg: "FR"
            }

        },


        methods: {


            formatDate(val) {
                if (val == undefined) return "";
                if (val == "") return "";


                let date = val.split("T")
                if (date.length != 2) {
                    date = val.split(" ")
                }

                let d = date[0].split("-")


                return d[2] + " / " + d[1] + " / " + d[0];

            },

            onChangeView(item) {

                this.$emit("changeView", item)
            },

            onCancel() {
                this.$emit("cancel")
            },

            onSaveSheet() {

                let record = {}

                for (let i = 0; i < this.template.children.length; i++) {
                    let tab = this.template.children[i].id;

                    for (let i = 0; i < this.$refs['sheet-' + tab].length; i++) {
                        record = { ...record, ...this.$refs['sheet-' + tab][i].getData() }
                    }

                }

                this.$emit("save", record)
            },
            onGenerateSheet() {

            }
        },
    }
</script>