<template>
  <div v-if=" (componentFormat ==undefined) || (componentFormat == 'number') || (componentFormat =='text')"
    class="mt-2 mb-1 w-full relative">
  
    <label :for="`${id}`" class="text-left block mb-1 text-sm font-semibold text-gray-800 dark:text-white">
      {{ label }} 


    </label>
    <div v-if="isButton" class="w-full inline-flex justify-start  items-center">
      <input v-if=" (componentFormat ==undefined) || (componentFormat == 'text') " :id="`${id}`" v-model="output"
        type="text"
        class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :placeholder="placeholder" :required="required" @keyup="onChangeKeyup" @input="onChange">

      <input v-if=" (componentFormat == 'number') " :id="`${id}`" v-model="output" type="text" step="0.01"
        class="text-right w-full bg-gray-50 border border-red-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :placeholder="placeholder" :required="required" @keyup="onChangeKeyup" @input="onChange">


      <button type="button"
        class="  ml-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        @click="onClickButton">
        <i class="fa-solid fa-ok mr-2 " />
        {{ common_labels['BUTTON_CHANGE'][lg] }}
      </button>
    </div>
    <div v-if="!isButton" class="w-full flex justify-start items-center">
      <input v-if=" (componentFormat ==undefined) || (componentFormat== 'text') " :id="`${id}`" v-model="output"
        type="text"
        class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :placeholder="placeholder" :required="required" @keyup="onChangeKeyup" @input="onChange">
 
      <input v-if=" (componentFormat == 'number') " :id="`${id}`" v-model="output" type="number" step="0.01"
        class="text-right w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :placeholder="placeholder" :required="required" @keyup="onChangeKeyup" @input2="onChange">
    </div>
  </div>


  <div v-if="componentFormat =='phone'" class="mt-2 mb-1 w-full relative">
    <label :for="`${id}`" class="text-left block mb-1 text-sm font-semibold text-gray-800 dark:text-white">
      {{ label }}
    </label>
    <div class="flex items-center">
      <button :id="`dropdown-button-country-${id}`"
        class="flex-shrink-0 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
        type="button" @click="showPopupCountry()">
        <span class="mr-2 fi  " :class="selected_flag" />
        {{ selected_prefix }}
        <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m1 1 4 4 4-4" />
        </svg>
      </button>
      <div :id="`dropdown-input-country-${id}`"
        class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-64 dark:bg-gray-700 max-h-96 overflow-y-auto">
        <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
          <li v-for="country in countries" :key="country.id">
            <button type="button" class=" inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100
                            dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem"
              @click="selectOptionCountry(country)">
              <span class="inline-flex items-center truncate">

                <span class="fi mr-1" :class="country.flag" />
                {{ country.name?.fr }} ({{ country.prefix }})
              </span>
            </button>
          </li>
        </ul>
      </div>

      <div class="relative w-full">
        <input id="phone-input" v-model="output" type="text"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :pattern="selected_pattern" :placeholder="selected_placeholder" @input="onChange">
      </div>
    </div>
  </div>

  <div
    v-if="componentFormat =='distance' || componentFormat =='mass' || componentFormat =='mass-volume' || componentFormat =='volume' || componentFormat =='famous' || componentFormat =='energy' || componentFormat =='percent' || componentFormat =='currency' "
    class="mt-2 mb-1 w-full relative ">
 
    <label :for="`${id}`" class="text-left block mb-1 text-sm font-semibold  text-gray-900 dark:text-white truncate">
      {{ label }}

    </label>
    <div class="flex">
      <button v-if="config?.prefix != false" :id="`dropdown-button-sign-${id}`"
        :data-dropdown-toggle2="`dropdown-input-sign-${id}`"
        class="w-10  flex-shrink-0 z-10 inline-flex items-center py-2.5 px-2 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300   hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 justify-center"
        type="button" @click="showPopupSign()">
        {{ sign }}
      </button>
      <div v-if="config?.prefix != false" :id="`dropdown-input-sign-${id}`"
        class="z-50 hidden bg-white divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 w-12">
        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200 w-12" :aria-labelledby2="`dropdown-input-sign-${id}`">
          <li v-for="item in signs" :key="item" @click="selectOptionSign(item)">
            <button type="button"
              class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
              role="menuitem">
              <div class="inline-flex items-center">
                {{ item }}
              </div>
            </button>
          </li>
        </ul>
      </div>



      <div class="relative w-full">
        <input :id="`${id}`" v-model="output" type="number" step="0.01"
          class="text-right block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50   border-r-gray-50 border-r-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-r-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
          :placeholder="placeholder" :required="required" @keyup="onChangeKeyup" @input="onChange">
      </div>

      <button :id="`dropdown-button-unit-${id}`" :data-dropdown-toggle2="`dropdown-input-unit-${id}`"
        class="w-10  flex-shrink-0 z-10 inline-flex items-center py-2.5 px-2 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300   hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 justify-center"
        type="button" @click="showPopup()">
        {{ unit }}
      </button>
      <div :id="`dropdown-input-unit-${id}`"
        class="z-50 hidden bg-white divide-y divide-gray-100   shadow w-44 dark:bg-gray-700 w-12">
        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200 w-12" :aria-labelledby2="`dropdown-input-${id}`">
          <li v-for="item in items" :key="item" @click="selectOption(item)">
            <button type="button"
              class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
              role="menuitem">
              <div class="inline-flex items-center">
                {{ item }}
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import common_labels from "@/assets/lg/common.json"
  import 'flag-icons/css/flag-icons.min.css';

  import { Dropdown } from 'flowbite';
  export default {
    name: 'ContentInput',
    components: {},
    props: {
      id: String,
      label: String,
      index: String,
      placeholder: String,
      required: Boolean,
      value: [Object, String],
      config: {},
      format: String,
      dunit: String,
      visible: Boolean,
      record: Object,
      countries: Array,
      isButton: Boolean,

    },
    emits: ["updateField", "valueChanged", "manageField"],
    data() {
      return {
        output: "",
        selected_pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
        selected_placeholder: "123-456-7890",

        phone: "",

        selected_prefix: "+33",
        selected_flag: "fi-fr",
        selected_flag2: "<svg class='w-4 h-4 me-2' fill='none' viewBox='0 0 20 15'><rect width='19.1' height='13.5' x='.25' y='.75' fill='#fff' stroke='#F5F5F5' stroke-width='.5' rx='1.75' /><mask id='a' style='mask-type:luminance' width='20' height='15' x='0' y='0' maskUnits='userSpaceOnUse'><rect width='19.1' height='13.5' x=''.25' y='.75' fill='#fff' stroke='#fff' stroke-width='.5' rx='1.75' /></mask><g mask='url(#a)'><path fill='#F44653' d='M13.067.5H19.6v14h-6.533z' /><path fill='#1035BB' fill-rule='evenodd' d='M0 14.5h6.533V.5H0v14z' clip-rule='evenodd' /></g></svg>",
        prefix: "",
        lg: "FR",
        unit: "",
        empty: {},
        sign: "",
        tt: "champ externe",
        items: [],
        scanModal: null,
        common_labels: common_labels,
        dropdownSign: null,
        dropdownCountry: null,
        famous: [
          'uvc',
          'kg',
          'g',
          'mg',
          'µg',
          'l',
          'cl',
          'ml',
          "m3",
          'm',
          'cm',
          'mm'
        ],


        distance: [
          'm',
          'cm',
          'mm'
        ],
        volume: [
          'l',
          'cl',
          'ml',
          "m3"
        ],
        currency: [
          'eur',
          'usr'
        ],
        mass: [
          'kg',
          'g',
          'mg',
          'µg'
        ],
        energy: [
          'kcal',
          'cal',
          'kj',
          'j'
        ],
        percent: [
          '%'
        ],
        signs: [
          '',
          '<',
          '>',
          '~',
          '=',
          '-'
        ],

        options: {
          placement: 'bottom',
          triggerType: 'click',
          offsetSkidding: 0,
          offsetDistance: 10,
          delay: 300,
          //  onHide: () => {

          //  },
          //  onShow: () => {

          //  },
          //  onToggle: () => {

          //  }
        },
        dropdown: null
      }
    },
    computed: {
      componentFormat: function () {
        if (this.format != undefined) return this.format;

        return this.config.format
      }
    },
    watch: {
      value2: function (val) {

        this.setFormat()

        if (val != undefined) {
          if (typeof val == 'object') {
            if (this.config?.format == "phone") {
              this.output = val?.value?.replace(",", ".");
              this.prefix = val?.prefix?.replace(",", ".");
            }
            else {
              this.output = val?.value?.replace(",", ".");
              this.sign = val?.sign
              this.unit = val?.unit
              if (this.config?.format == "percent") this.unit = "%"
            }
          }
          else
            if (typeof val == 'string') {
              this.output = val?.replace(",", ".");
            }
        }
      }
    },
    mounted() {



      this.setFormat()


      const $targetEl = document.getElementById("dropdown-input-unit-" + this.id);
      const $triggerEl = document.getElementById("dropdown-button-unit-" + this.id);

      if ($triggerEl != undefined)
        this.dropdown = new Dropdown($targetEl, $triggerEl, this.options);


      const $targetElSign = document.getElementById("dropdown-input-sign-" + this.id);
      const $triggerElSign = document.getElementById("dropdown-button-sign-" + this.id);

      if ($targetElSign != undefined)
        this.dropdownSign = new Dropdown($targetElSign, $triggerElSign, this.options);



      const $targetElCountry = document.getElementById("dropdown-input-country-" + this.id);

      const $triggerElCountry = document.getElementById("dropdown-button-country-" + this.id);

      if ($triggerElCountry != undefined) {
        this.dropdownCountry = new Dropdown($targetElCountry, $triggerElCountry, this.options);

      }

      // this.items = this.mass 

    },
    methods: {

      onClickButton: function () {
        this.$emit("action", { type: 'change-pincode', id: this.record._id, pincode: this.output })
      },
      setFormat: function () {

        let format = this.format;
        let unit = this.value?.unit;

        if (format == undefined) {
          format = this.config.format
        }


        if (unit == undefined) {
          unit = this.config.unit
        }

        this.unit = unit

        //if (this.value != undefined)
        {
          if (format == "famous") {

            this.items = this.famous;
          }
          if (format == "distance") {

            this.items = this.distance;
          }
          if (format == "volume") {

            this.items = this.volume;
          }
          if (format == "mass") {

            this.items = this.mass;
          }
          if (format == "percent") {
            this.unit = "%"
            this.items = this.percent;
          }

          if (format == "mass-volume") {


            this.items = [...this.mass, ...this.volume];
          }
          if (format == "energy") {

            this.items = this.energy;
          }
          if (format == "currency") {

            this.items = this.currency;
          }
          if (typeof this.value == 'object') {
            if (this.value == undefined) {
              this.output = ""
            }
            else
              if (typeof this.value.value == 'string') this.output = this.value?.value?.replace(",", ".");

            this.sign = this.value?.sign
          }

          if (typeof this.value == 'string') {
            this.output = this.value.replace(",", ".");
          }
        }
      },
      onChangeKeyup() {},
      onChange() {

        let value = this.output;

        let format = this.format;

        if (format == undefined) {
          format = this.config.format
        }

        if (format != undefined)
          value = { value: this.output.toString(), unit: this.unit, sign: this.sign }

        console.log("Input / output", JSON.stringify(this.output))
        console.log("Input / value", JSON.stringify(value))

        this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })
      },
      showPopup: function () {
        //   this.dropdown.show()
        //   this.$emit("updateField", {   })
        //   this.$emit("manageField", {  })
      },
      showPopupCountry: function () {
        //   this.dropdown.show()
      },
      showPopupSign: function () {
        //   this.dropdown.show()
      },
      selectOption: function (choice) {
        this.unit = choice;
        let value = { value: this.output.toString(), unit: this.unit, sign: this.sign }
        this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })
        this.dropdown.hide()
        return false;
      },
      selectOptionCountry: function (choice) {
        console.log(choice)
        this.selected_prefix = choice.prefix
        this.selected_flag = choice.flag
        this.dropdownCountry.hide()
        return false;
      },
      selectOptionSign: function (choice) {
        this.sign = choice;

        let value = { value: this.output.toString(), unit: this.unit, sign: this.sign }
        this.$emit("valueChanged", { id: this.config.data, value: value, index: this.index })


        this.dropdownSign.hide()
        return false;
      },
      getData() {
        if (this.config.format == undefined) return this.output
        if (this.config.format == 'phone') return { value: this.output, prefix: this.selected_prefix, format: 'phone' };
        return { value: this.output?.toString(), unit: this.unit, sign: this.sign, index: this.index }
      }
    }
  }
</script>