<template>
  <div class="relative  mt-2 mb-2 w-full ">
    <vue-pdf-embed
      ref="pdfRef3"
      class="w-full"
      :source="source2"
    />
  </div>
</template>


<script>
    import service from "@/services/FullService.vue"
    import { mapGetters } from "vuex";
    import VuePdfEmbed from 'vue-pdf-embed'
    export default {
        name: 'ContentPdf',
        components: {

            VuePdfEmbed,
        },
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,
            value: Object,
            record: Object,
            config: Object
        },
        computed:
        {
            ...mapGetters({ user: "StateUser" }),
            mapRef: function (a) {
                if (a == undefined) return null;
                return a;
            }
        },

        data() {
            return {
                source2: "",
                output: "",
                showPDF: false,
            }
        },
        watch: {
            value: async function (val) {
                this.output = val;


                if (this.value != undefined) {


 

                    let pdf = await service.getDataId("files/base64", this.findFileId(this.value.url) , this.user?.token, { fields: 'id,name, data' })
                   
                 
              //   let pdf = {data: null} 
           

                    this.source2 = pdf.data;
                    this.showPDF = true;
                }
            }
        },


        async mounted() {
            this.output = this.value;

            if (this.value != undefined) {
                let pdf = await service.getDataId("files/base64", this.value, this.user?.token, { fields: 'id,name, data' })


                this.source2 = pdf.data;
                this.showPDF = true;
            }



            this.$nextTick(function () {
                window.scrollTo(0, 0)
            });
        },
        methods: {

            onDownload: function () {

                this.downloadPDF(this.record.data, "FT-" + this.record.product.reference + "-" + this.profile + ".pdf")
            },
            downloadPDF: function (base64, filename) {

                // Create an anchor element
                var link = document.createElement("a");
                link.href = base64;
                link.download = filename;
                document.body.appendChild(link);
                // Click the link
                link.click();
                // Remove the link
                document.body.removeChild(link);
            },

            OnView: function (v) {
                this.viewBy = v


                this.$nextTick(() => {
                    if (v == 2) {

                        this.$refs.pdfRef1.render()
                        this.$refs.pdfRef2.render()
                    }
                });


            },

            findFileId : function(url)
            {
                let id = url.replaceAll("https://file-storage-ten.vercel.app/b4food/files/", "")

                return id;
            },
            loadPdf: async function (id) {
         
                if (this.user == undefined) {
                    //this.$router.push("/login");
                    window.location = "/login"
                    return;
                }
                this.showPDF = false;

             


                let pdf = await service.getDataId("technical-sheets", id, this.user?.token, { fields: 'id,name,market,langage,data' })

                //this.record = pdf;
                if (pdf == null) {
                    alert("Error load PDF = ", id)
                }
                else {


                    this.profile = pdf.market.toUpperCase() + "-" + pdf.langage.toUpperCase()


                    this.name = pdf.product.name;
                    this.reference = pdf.product.name;
                    this.newTS = pdf.technicalSheet;
                    this.source2 = pdf.data;

                    this.version = pdf.version;

                    this.status = pdf.status;
                }

                this.showPDF = true;

                this.$nextTick(function () {
                    window.scrollTo(0, 0)
                });
            },
            getData() {


            }
        }
    }
</script>


<style>
    .vue-pdf-embed>div {
        margin-bottom: 8px;
        box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }

    .vue-pdf-embed>div>div>canvas {
        min-height: 400px;
        width: 700px
    }
</style>