<template>
  <section class="w-full bg-gray-50 dark:bg-gray-900 mt-6   ">
    <div class="w-full flex items-center ">
      <div class="w-0 bg-yellow-200 ">
        <div id="my-week-picker" rangepicker-weekStart="1"
          class="flex items-center mb-3 border-0 border-gray-300 bg-red-200" @click="OnSelectWeek">
          <div class="relative  ">
            <input id="date-picker-pv" type="text" hidden datepicker-autohide datepicker-weekstart="1" readonly
              class="opacity-0 cursor-event border-0  bg-red-200  text-gray-900 text-sm rounded-sm focus:ring-0   block w-0   py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="" @changeDate="OnChangeStartDate">
          </div>

          <div class="relative  hidden ">
            <input name="end" type="text" disabled
              class="hidden pointer-events-none  border-0  bg-red-200  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24 py-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="" @changeDate="OnChangeEndDate">
          </div>
        </div>
      </div>
      <div class="mb-3 flex items-center">
        <div class=" w-72 text-left " @click2="onOpenDatePicker" @click="OnSelectWeek">
          Période :
          {{ period?.display_start }} - {{ period.display_end }}
        </div>
        <div class="border border-gray-300 hover:bg-gray-100 cursor-pointer py-0.5 px-2 text-sm mr-1"
          @click="subtrackWeek()">
          <i class="fa-solid fa-chevron-left " />
        </div>
        <div class="border border-gray-300 hover:bg-gray-100 cursor-pointer py-0.5 px-2  text-sm ml-1"
          @click="addWeek()">
          <i class="fa-solid fa-chevron-right  " />
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="w-full   mx-auto max-w-screen-2xl  ">
      <h1 class="text-2xl mt-6">
        {{ msg_waiting[lg] }}
      </h1>
    </div>

    <div v-if="!isLoading" class="w-full  h-full  mx-auto mt-3  ">
      <div class="  bg-white-100  overflow-hidden   bottom-10 bg-white shadow-md dark:bg-gray-800  "
        style="min-height:650px">
        <div v-if="config != undefined && (config.options == undefined || config.options.topBar != false)"
          class="hidden flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
          <div class="flex items-center w-full space-x-3">
            <div class="pr-3 " :class="{'invisible': !config.options?.displayBack}">
              <a class="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                @click="onBack">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                    clip-rule="evenodd" />
                </svg>
              </a>
            </div>



            <div
              class="flex ring-0 items-center w-2/5 rounded-lg bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block  py-1">
              <div class="flex flex-col  w-full bg-gray-400">
                <div id="mega-menu-dropdown-button" class="relative   flex items-start w-full"
                  data2-dropdown-toggle="mega-menu-dropdown" data-dropdown-offset-distance="2"
                  data-dropdown-offset-skidding="0" @change="closeSearchBar" @keyup="closeSearchBarKeyup($event)">
                  <div class="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input id="input-search" v-model="globalQuery" type="text" style=" outline: none;"
                    class="bg-gray-50 border-none  w-full text-gray-900 text-sm  focus:border-0 focus:ring-0 ring-2 ring-transparent ring-inset  border-transparent block w-full pl-10 pr-2  "
                    placeholder="Nom, Référence ..." @input="onSearchInput" @focus="onSeachFocus($event)">

                  <button type="button" class="hidden  absolute inset-y-0 right-6 flex items-center pr-3"
                    @click="onEraseSearch($event)">
                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                      <path
                        d="M18.85 1.1A1.99 1.99 0 0 0 17.063 0H2.937a2 2 0 0 0-1.566 3.242L6.99 9.868 7 14a1 1 0 0 0 .4.8l4 3A1 1 0 0 0 13 17l.01-7.134 5.66-6.676a1.99 1.99 0 0 0 .18-2.09Z" />
                    </svg>
                  </button>
                  <button :class="{'hidden2': !isExtended}" type="button"
                    class="  absolute inset-y-0 right-0 flex items-center pr-3" @click="onEraseSearch($event)">
                    <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                  </button>

                  <div id="mega-menu-dropdown"
                    class="  z-10    hidden w-full    bg-gray-100 border border-gray-300 rounded-b-lg   dark:border-gray-700 dark:bg-gray-700">
                    <div v-if="globalQuery.length > 0"
                      class=" px-4 pt-4 pb-2  text-gray-900 md:pb-2 dark:text-white overflow-y-auto">
                      <ul class="space-y-1  overflow-y-auto min-h-48 max-h-48"
                        aria-labelledby="mega-menu-dropdown-button">
                        <li v-for="search in results?.matching" :key="search.id">
                          <button type="button"
                            class="relative inline-flex items-center w-full px-4 pt-2 pb-1 text-sm font-medium  hover:bg-gray-200 hover:text-gray-700  hover:font-bold  dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                            @click="onSearchItemSelected({reference : search.reference, name : search.name})">
                            <div class="w-full flex items-center  ">
                              <svg class="w-4 h-4 mr-2.5 text-gray-500 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path
                                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                              </svg>
                              <div class="w-full justify-start text-left">
                                {{ search.name }}
                              </div>
                              <div class="justify-end text-right">
                                {{ search.reference }}
                              </div>
                            </div>
                          </button>
                        </li>
                      </ul>
                      <div class="border-t border-gray-700 flex justify-center items-end  ">
                        <button type="button"
                          class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-lg font-bold text-gray-700       focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                          @click="onClickRefresh">
                          {{ common_labels['BUTTON_SEE_ALL'][lg] }}
                        </button>
                      </div>
                    </div>


                    <div v-if="globalQuery.length < 1"
                      class="bg-gray-100  p-4 pb-0 text-gray-900 md:pb-4 dark:text-white overflow-y-auto">
                      <div class="  mt-1 w-full border-b-2   border-b-gray-300   flex flex-row items-center py-2 px-3">
                        <button v-for="button in results?.buttons" :key="button" type="button"
                          class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                          {{ button }}
                        </button>
                      </div>
                      <label
                        class="flex mb-1 w-full font-bold text-sm text-gray-800 text-left  border-t border-gray-500 border-t justify-start pt-2">
                        Récents
                      </label>
                      <ul class="space-y-1 h-48 overflow-y-auto" aria-labelledby="mega-menu-dropdown-button">
                        <li v-for="search in results?.latest" :key="search.id">
                          <button type="button"
                            class="relative inline-flex items-center w-full px-4 pt-2 pb-1 text-sm font-medium  hover:bg-gray-200 hover:text-gray-700  hover:font-bold focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                            @click="onSearchLastestItemSelected(search)">
                            <svg class="w-3 h-3 mr-2.5 text-gray-800 dark:text-white" aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" stroke-linejoin="round" stroke-width="2"
                                d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            {{ search.name }}
                          </button>
                        </li>
                      </ul>
                      <label
                        class="hidden flex mb-1 w-full font-bold text-sm text-gray-800 text-left  border-t border-gray-500 border-t justify-start pt-2">Suggestions</label>

                      <ul class="hidden h-48 overflow-y-auto">
                        <li v-for="search in results?.suggestions" :key="search.id">
                          <button type="button"
                            class="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg hover:bg-gray-200 hover:font-bold hover:text-gray-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                            <svg class="w-3 h-3 mr-2.5 text-gray-500 dark:text-white" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                              <path
                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                            </svg>
                            {{ search.name }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="hidden w-96 text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  :class="{'hidden': !isExtended}">
                  <button type="button"
                    class="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                    <svg class="w-3 h-3 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor" viewBox="0 0 20 20">
                      <path
                        d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                      <path
                        d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                    </svg>
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div v-if="isLoading2" class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800  ">
          <h1 class="text-2xl mt-6 text-black">
            {{ msg_waiting[lg] }}
          </h1>
        </div>
        <div v-if="!isLoading2" class="overflow-x-auto  bg-white px-6 pt-2 mb-12  mt-12">
          <div class="grid grid-cols-6 gap-2 w-full">
            <div class="opacity-0 border border-gray-700  bg-gray-700 font-bold text-white">
              Produit
            </div>
           
            <div class="border border-gray-700  bg-gray-700 font-bold text-white">
              {{ displayColumTitle(0) }} +++
            </div>
            <div class="border border-gray-700  bg-gray-700 font-bold text-white">
              {{ displayColumTitle(1) }}
            </div>
            <div class="border border-gray-700  bg-gray-700 font-bold text-white">
              {{ displayColumTitle(2) }}
            </div>
            <div class="border border-gray-700  bg-gray-700 font-bold text-white">
              {{ displayColumTitle(3) }}
            </div>
            <div class="border border-gray-700  bg-gray-700 font-bold text-white">
              {{ displayColumTitle(4) }}
            </div>
            <div v-for="(cell, index3) in cells" :key="cell.id" class="hidden">
              <div v-if="cell.type == 'y'"
                class="border border-gray-300 h-12 p-3 font-semibold flex items-center justify-center h-12 text-sm">
                {{ cell?.value }}
              </div>

              <div v-if="cell.type == 'v'" :id="`popover-hover-button-${id}-${index3}`" data-popover-trigger="hover"
                class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-12  hover:border-2 hover:border-gray-400"
                @mouseover="onHoverPopup2(index3)">
                <div class="w-full text-sm font-semibold bg-green-100 h-full flex flex-row items-center px-3"
                  :class="cell?.values?.c1">
                  <i class="fa-solid fa-temperature-half pr-2" /> {{ cell?.values?.v1 }} ++
                </div>
              </div>


              <div v-if="cell.type == 'w'" :id="`popover-hover-button-${id}-${index3}`"
                class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-12 hover:border-2 hover:border-gray-400"
                @mouseover="onHoverPopup2(index3)">
                <div class="w-6/12 text-sm font-semibold bg-green-100 h-full flex flex-row items-center px-3 "
                  :class="cell?.values?.c1">
                  <i class="fa-solid fa-temperature-half pr-2" /> {{ cell?.values?.v1 }} +++
                </div>

                <div class="w-6/12 text-sm font-semibold  bg-green-100 flex flex-row items-center px-3  h-full"
                  :class="cell?.values?.c2">
                  <i class="fa-solid fa-temperature-low  pr-2" /> {{ cell?.values?.v2 }} +++
                </div>
              </div>



              <div v-if="cell.type == 'e'" class="border border-gray-300 text-left p-3 bg-gray-100  h-12" />


              <div v-if="cell.type == 'm'" :id="`popover-hover-button-${id}-${index3}`"
                class="cursor-pointer border border-gray-300 text-left p-3 bg-red-100 flex items-center justify-center text-sm font-semibold  h-12 hover:border-2 hover:border-gray-400"
                @mouseover="onHoverPopup2(index3)">
                <i class="fa-solid fa-triangle-exclamation mr-2" />
                Manquant
              </div>


              <div :id="`popover-hover-menu-${id}-${index3}`" data-popover role="tooltip"
                class="absolute z-10 invisible inline-block w-96 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-300 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                <div class="w-full ">
                  <div
                    class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                    <h3 class="font-semibold text-gray-900 dark:text-white">
                      Fiche d'Information
                    </h3>
                  </div>
                  <div class="px-3 py-2 flex flex-col w-full">
                    <div class=" text-sm font-normal  h-full flex flex-row items-center px-3 ">
                      <i class="fa-solid fa-temperature-half pr-2 w-6" /><span>Date de saisie
                        :</span>&MediumSpace;<span class="font-semibold">{{ cell?.values?.date }}</span>
                    </div>

                    <div class=" text-sm font-normal  h-full flex flex-row items-center px-3 ">
                      <i class="fa-solid fa-temperature-half pr-2 w-6" /><span>Température
                        :</span>&MediumSpace;<span class="font-semibold">{{ cell?.values?.v1 }}</span>
                    </div>

                    <div
                      v-if="(cell?.values?.v2 != undefined) && (cell?.values?.v2 != '-°C')&& (cell?.values?.v2 != 'null°C')"
                      class="text-sm font-normal  flex flex-row items-center px-3  h-full">
                      <i class="fa-solid fa-temperature-low  pr-2 w-6" /><span>Témpérature
                        Humide :</span>&MediumSpace;<span class="font-semibold">{{ cell?.values?.v2 }}</span>
                    </div>

                    <div class=" text-sm   h-full flex flex-row items-center px-3 font-normal ">
                      <i class="fa-solid fa-user pr-2 w-6" /><span>Réalisé par
                        :</span>&MediumSpace;<span class="font-semibold">{{ cell?.values?.member }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="py-1 bg-gray-50 border-t border-t-gray-300 text-xs justify-center font-normal h-full flex flex-row items-center px-3 ">
                  <div class="cursor-pointer" @click="openDetails(cell)">
                    <span>Voir le détail <i class="fa-solid fa-up-right-from-square pl-2 w-6" /></span>
                  </div>
                </div>
                <div data-popper-arrow />
              </div>
            </div>
          </div>

          <div v-for="(cell,index2 ) in cells" :key="cell.id" class="grid grid-cols-6 gap-2 w-full">
            <div class="border border-gray-300 h-20 p-3 font-semibold flex items-center justify-center h-24 text-sm">
              <div class="flex flex-col w-full">
                <div class="flex flex-row w-full justify-center text-left">
                  <div class="font-bold   w-full  justify-center">
                    {{ cell.name }}
                  </div>
                </div>
                <div class="flex flex-row w-full text-left">
                  <div class="italic w-full  justify-center text-gray-500">
                    {{ cell?.brand?.name }}
                  </div>
                </div>
              </div>
            </div>
            <div :id="`popover-hover-button-${id}-4-${index2}`" data-popover-trigger="hover"
              class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-24  hover:border-2 hover:border-gray-400"
              @mouseover="onHoverPopup(4,index2,cell.w4)">
              <div class="w-full text-sm font-semibold h-full flex flex-row items-center px-3"
                :class="{'bg-green-100' : (cell?.w4?.length > 0)}">
                <div class="flex flex-row w-full">
                  <div class="flex flex-col text-gray-600 ">
                    <div class="w-full">{{id}}-4-{{index2}}</div>
                    <div class="">
                      Date :
                    </div>
                    <div class="">
                      N° lot :
                    </div>
                    <div class="">
                      DDM :
                    </div>
                  </div>
                  <div v-for="batch in cell.w4" :key="batch.number"
                    class="border justify-start flex flex-col  px-1 text-gray-900 ">


                    <div class=" ">
                      {{ formatDate(batch.opened_at) }}
                    </div>
                    <div class=" ">
                      {{ batch.batch_number }}
                    </div>
                    <div class="">
                      {{ batch.end_of_life }}
                    </div>
                  </div>
                </div>
              </div>
              <div :id="`popover-hover-menu-${id}-4-${index2}`" data-popover role="tooltip"
                class="absolute z-10 invisible inline-block w-96 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-300 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                <FileColDisplayBatch :items="cell.w4" />
                
              
                <div
                  class="hidden py-1 bg-gray-50 border-t border-t-gray-300 text-xs justify-center font-normal h-full flex flex-row items-center px-3 ">
                  <div class="cursor-pointer" @click="openDetails(cell)">
                    <span>Voir le détail <i class="fa-solid fa-up-right-from-square pl-2 w-6" /></span>
                  </div>
                </div>
                <div data-popper-arrow />
              </div>
            </div>
            <div :id="`popover-hover-button-${id}-3-${index2}`" data-popover-trigger="hover"
              class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-24  hover:border-2 hover:border-gray-400"
              @mouseover="onHoverPopup(3,index2,cell.w3)">
              <div class="w-full text-sm font-semibold   h-full flex flex-row items-center px-3"
                :class="{'bg-green-100' : (cell?.w3?.length > 0)}">
                <div class="flex flex-row w-full">
                  <div class="flex flex-col  text-gray-600">
                    <div class="w-full">{{id}}-3-{{index2}}</div>
                    <div class="">
                      Date :
                    </div>
                    <div class="">
                      N° lot :
                    </div>
                    <div class="">
                      DDM :
                    </div>
                  </div>
                  <div v-for="batch in cell.w3 " :key="batch.number" class="flex flex-col  px-2  text-gray-900">
                    <div class="">
                      {{ formatDate(batch.opened_at) }}
                    </div>
                    <div class="">
                      {{ batch.batch_number }}
                    </div>
                    <div class="">
                      {{ batch.end_of_life }}
                    </div>
                  </div>
                </div>
              </div>
              <div :id="`popover-hover-menu-${id}-3-${index2}`" data-popover role="tooltip"
                class="absolute z-10 invisible inline-block w-96 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-300 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
                <div class="w-full ">
                      <FileColDisplayBatch :items="cell.w3" />
                  
                </div>
                <div
                  class="hidden py-1 bg-gray-50 border-t border-t-gray-300 text-xs justify-center font-normal h-full flex flex-row items-center px-3 ">
                  <div class="cursor-pointer" @click="openDetails(cell)">
                    <span>Voir le détail <i class="fa-solid fa-up-right-from-square pl-2 w-6" /></span>
                  </div>
                </div>
                <div data-popper-arrow />
              </div>
            </div>
            <div :id="`popover-hover-button-${id}-2-${index2}`" data-popover-trigger="hover"
              class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-24  hover:border-2 hover:border-gray-400"
              @mouseover="onHoverPopup(2,index2,cell.w2)">
              <div class="w-full text-sm font-semibold   h-full flex flex-row items-center px-3"
                :class="{'bg-green-100' : (cell?.w2?.length > 0)}">
                <div class="flex flex-row w-full">
                  <div class="flex flex-col  text-gray-600">
                    <div class="">
                      Date :
                    </div>
                    <div class="">
                      N° lot :
                    </div>
                    <div class="">
                      DDM :
                    </div>
                  </div>
                  <div v-for="batch in cell.w2" :key="batch.number" class="flex flex-col  px-2  text-gray-900">
                    <div class="">
                      {{ formatDate(batch.opened_at) }}
                    </div>
                    <div class="">
                      {{ batch.batch_number }}
                    </div>
                    <div class="">
                      {{ batch.end_of_life }}
                    </div>
                  </div>
                </div>
              </div>
              <div :id="`popover-hover-menu-${id}-2-${index2}`" data-popover role="tooltip"
              class="absolute z-10 invisible inline-block w-96 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-300 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
              <div class="w-full ">
                <FileColDisplayBatch :items="cell.w2" />
                
              </div>
              <div
                class="hidden py-1 bg-gray-50 border-t border-t-gray-300 text-xs justify-center font-normal h-full flex flex-row items-center px-3 ">
                <div class="cursor-pointer" @click="openDetails(cell)">
                  <span>Voir le détail <i class="fa-solid fa-up-right-from-square pl-2 w-6" /></span>
                </div>
              </div>
              <div data-popper-arrow />
            </div>
            </div>
            <div :id="`popover-hover-button-${id}-1-${index2}`" data-popover-trigger="hover"
              class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-24  hover:border-2 hover:border-gray-400"
              @mouseover="onHoverPopup(1,index2,cell.w1)">
              <div class="w-full text-sm font-semibold  h-full flex flex-row items-center px-3"
                :class="{'bg-green-100' : (cell?.w1?.length > 0)}">
                <div class="flex flex-row w-full">
                  <div class="flex flex-col  text-gray-600">
                    <div class="">
                      Date :
                    </div>
                    <div class="">
                      N° lot :
                    </div>
                    <div class="">
                      DDM :
                    </div>
                  </div>
                  <div v-for="batch in cell.w1" :key="batch.number" class="flex flex-col  px-2  text-gray-900">
                    <div class="">
                      {{ formatDate(batch.opened_at) }}
                    </div>
                    <div class="">
                      {{ batch.batch_number }}
                    </div>
                    <div class="">
                      {{ batch.end_of_life }}
                    </div>
                  </div>
                </div>
              </div>
              <div :id="`popover-hover-menu-${id}-1-${index2}`" data-popover role="tooltip"
              class="absolute z-10 invisible inline-block w-96 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-300 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
              <div class="w-full ">
                <FileColDisplayBatch :items="cell.w1" />
               
               
              </div>
              <div
                class="hidden py-1 bg-gray-50 border-t border-t-gray-300 text-xs justify-center font-normal h-full flex flex-row items-center px-3 ">
                <div class="cursor-pointer" @click="openDetails(cell)">
                  <span>Voir le détail <i class="fa-solid fa-up-right-from-square pl-2 w-6" /></span>
                </div>
              </div>
              <div data-popper-arrow />
            </div>
            </div>
            <div :id="`popover-hover-button-${id}-0-${index2}`" data-popover-trigger="hover"
              class="cursor-pointer border border-gray-300 text-left  flex flex-row items-center  h-24  hover:border-2 hover:border-gray-400"
              @mouseover="onHoverPopup(0,index2,cell.w0)">
              <div class="w-full text-sm font-semibold  h-full flex flex-row items-center px-3"
                :class="{'bg-green-100' : (cell?.w0?.length > 0)}">
                <div class="flex flex-row w-full">
                  <div class="flex flex-col  text-gray-600">

 

                    <div class="">
                      Date :
                    </div>
                    <div class="">
                      N° lot :
                    </div>
                    <div class="">
                      DDM :
                    </div>
                  </div>
                  <div v-for="batch in cell.w0" :key="batch.number" class="flex flex-col  px-2  text-gray-900">
                    <div class="">
                      {{ formatDate(batch.opened_at) }}
                    </div>
                    <div class="">
                      {{ batch.batch_number }}
                    </div>
                    <div class="">
                      {{ batch.end_of_life }}
                    </div>
                  </div>
                </div>
              </div>
              <div :id="`popover-hover-menu-${id}-0-${index2}`" data-popover role="tooltip"
              class="absolute z-10 invisible inline-block w-96 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-300 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
              <div class="w-full ">
                <FileColDisplayBatch :items="cell.w0" />
              
              </div>
              <div
                class="hidden py-1 bg-gray-50 border-t border-t-gray-300 text-xs justify-center font-normal h-full flex flex-row items-center px-3 ">
                <div class="cursor-pointer" @click="openDetails(cell)">
                  <span>Voir le détail <i class="fa-solid fa-up-right-from-square pl-2 w-6" /></span>
                </div>
              </div>
              <div data-popper-arrow />
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
  const MAX_ITEMS = 10;
  import labels from "@/assets/lg/common.json"
  import blank from "@/assets/img/blank.png"
  import Handlebars from "handlebars";
  import { Dropdown } from 'flowbite';
  import { Popover } from 'flowbite';
  //import Datepicker from 'flowbite-datepicker/Datepicker';

  import DateRangePicker from 'flowbite-datepicker/DateRangePicker';

    import FileColDisplayBatch from '@/components/FileColDisplayBatch.vue'

  export default {
    name: 'FiveColComponent',

    components: {
      FileColDisplayBatch
    },
    props: {
      id: String,
      config: Object,
      records: Object,
      offset: Number,
      limit: Number,
      currentTab: Number,
      isLoading: Boolean,
      isLoading2: Boolean,
      isLoading3: Boolean,
      filterList: Array,
      results: Object
    },
    data() {
      return {
        lg: "FR",
        page: 1,
        dropdownSearch: null,
        msg_waiting: {
          "EN": "Please wait a moment",
          "FR": "Merci de patienter"
        },
        comment: "Default",
        people: [],

        period: { start: "", end: "" },
        selectedPerson: {},
        query: '',
        selectedItem: '',
        isExtended: false,
        category: null,
        select_values: {},
        start_page: 1,
        selected: [],
        filters: {},
        blank: blank,
        globalQuery: '',
        loading2: false,
        current_page: 1,
        common_labels: labels,
        sortColId: null,
        sortOrder: "up",
        sortAttribut: "",
        date_end: "",
        date_start: "",
        cells: [],
        popupHoverSelection: {},
        dropdownWeekSelection: null


      }
    },


    computed: {
      isBack: function () {
        return (this.$route?.query?.back) || (this.config?.options?.displayBack == true)
      },


      filteredPeople: function () {

        this.$emit("query", this.query)
        //if (this.query === '') return this.people;



        return this.filterList;

        /*
 
       if (this.query === '') return this.people;


       let m = this;
       return this.people.filter(function (person) {
           return person.toLowerCase().includes(m.query.toLowerCase())
       })*/

      },


      first_item: function () {
        return this.offset + 1;
      },
      last_item: function () {

        if (this.records == undefined) return 0;

        let n = this.offset + this.limit

        if (n > this.records.total) return this.records.total
        return this.offset + this.limit;
      },
      max_page: function () {
        if (this.records == undefined) return MAX_ITEMS;
        if ((this.records.total % MAX_ITEMS) == 0) return (this.records.total / MAX_ITEMS)
        return parseInt(this.records.total / MAX_ITEMS) + 1;
      },
      displayPagination: function () {
        return (this.max_page > 1)

      },
      previous_valid: function () {
        if (this.current_page == 1) return false;
        return true;
      },
      next_valid: function () {
        if (this.current_page == this.max_page) return false;
        return true;
      },
      tripledots_valid: function () {
        if (this.max_page < 3) return false;
        return true;
      },
    },
    watch: {

      "offset": function () {
        this.start_page = 1 + this.offset / 10;
      },

      "records": function () {
        this.cells = this.records?.records;
      },
      "selectedItem": function (/*val*/) {


      },
      "$route.query.code": function () {

        //   this.current_page = 1;
        //   this.start_page = 1;
        //   this.max_page = 1;
        this.cells = this.records?.records;

        this.globalQuery = "";
        this.$emit("refreshPage", { collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })

      },

      config: function () {


        if (this.config?.tabs != undefined)
          if (this.config.tabs.length > 0)
            this.category = this.config?.tabs[this.currentTab].id;


        this.cells = this.records?.records;
      },
      "$route.params.id": function () {
        this.current_page = 1;
        this.start_page = 1;
        this.globalQuery = "";
        if (this.config?.tabs != undefined) {
          if (this.config.tabs.length > 0)
            this.category = this.config?.tabs[this.currentTab].id;
        }
      }
    },


    async mounted() {

      if (this.config.tabs != undefined)
        this.category = this.config.tabs[this.currentTab].id;

      let p = this.begEndWeek(new Date())

      this.period.start = p.b;
      this.period.end = p.e;

      this.period.display_start = p.b.toLocaleDateString();
      this.period.display_end = p.e.toLocaleDateString();



      if (this.$route.query.page != undefined) {
        this.current_page = parseInt(this.$route.query.page)

        if (this.current_page < 1) this.current_page = 1;
      }
      this.cells = this.records?.records;
      this.start_page = 1 + this.offset / 10;




    },

    methods: {

      formatDate(d) {
        if (d == undefined) return ""
        if (d == "") return ""
        let s = d.split("T")
        return s[0]
      },

      displayColumTitle(n) {
        if (this.period == undefined) return "???"
        if (this.period.start == undefined) return "???"
        if (this.period.end == undefined) return "???"

        if (this.period.start == "") return "???"
        if (this.period.end == "") return "???"

        console.log("period.start", this.period.start)
        console.log("period.end", this.period.end)


        let d1 = new Date(this.period.start + 7 * n)
        d1.setDate(d1.getDate() + 7 * n)
        console.log("d1", d1)

        let d2 = new Date(this.period.start)
        d2.setDate(d2.getDate() + 7 * n + 6)
        //  console.log("d2", d2)
        let ds1 = d1.toISOString().split("T")[0]
        let ds2 = d2.toISOString().split("T")[0]

        let s1 = ds1.split("-")
        let s2 = ds2.split("-")

        return s1[2] + "/" + s1[1] + "/" + s1[0] + " - " + s2[2] + "/" + s2[1] + "/" + s2[0]
      },

      subtrackWeek() {
        if (this.dropdownWeekSelection == undefined) {
          {
            let sevendays = new Date(this.period.start)
            sevendays.setDate(sevendays.getDate() - 7);
            this.period.start = sevendays
            this.period.display_start = sevendays.toLocaleDateString()
          }
          {
            let sevendays = new Date(this.period.end)
            sevendays.setDate(sevendays.getDate() - 7);
            this.period.end = sevendays
            this.period.display_end = sevendays.toLocaleDateString()
          }

          this.$emit("refresh", { filter: { start_period: this.period.start.toISOString(), end_period: this.period.end.toISOString() } })
          return
        }

        let da = this.dropdownWeekSelection.getDates()



        let sevendays = new Date(da[0])
        sevendays.setDate(sevendays.getDate() - 7 * 5);

        da[0] = new Date(sevendays)


        this.dropdownWeekSelection.datepickers[0].setDate(sevendays)
      },
      addWeek() {

        // console.log(this.period.display_start)

        if (this.dropdownWeekSelection == undefined) {

          {
            let sevendays = new Date(this.period.start)
            sevendays.setDate(sevendays.getDate() + 7);
            this.period.start = sevendays
            this.period.display_start = sevendays.toLocaleDateString()
          }
          {
            let sevendays = new Date(this.period.end)
            sevendays.setDate(sevendays.getDate() + 7);
            this.period.end = sevendays
            this.period.display_end = sevendays.toLocaleDateString()
          }

          this.$emit("refresh", { filter: { start_period: this.period.start.toISOString(), end_period: this.period.end.toISOString() } })
          return
        }


        let da = this.dropdownWeekSelection.getDates()



        let sevendays = new Date(da[0])
        sevendays.setDate(sevendays.getDate() + 7 * 5);

        da[0] = new Date(sevendays)




        this.dropdownWeekSelection.datepickers[0].setDate(sevendays)
      },


      OnChangeStartDate(s) {

        console.log("OnChangeStartDate - s.detail.date", s.detail.date)


        console.log("OnChangeStartDate - s.detail...getDate", s.detail?.datepicker?.rangepicker.getDates())

        let sevendays = new Date(s.detail.date)
        sevendays.setDate(sevendays.getDate() + 7 - 1);
        s.detail?.datepicker.rangepicker.setDates(s.detail.date, sevendays)

        s.detail?.datepicker?.hide()
      },
      OnChangeEndDate(s) {
        console.log("OnChangeEndDate - s.detail.date", s.detail.date)

        let ds = s.detail?.datepicker?.rangepicker.getDates();


        this.period.display_start = (new Date(ds[0])).toLocaleDateString()
        this.period.display_end = (new Date(ds[1])).toLocaleDateString()

        this.period.start = new Date(ds[0])
        this.period.end = new Date(ds[1])

        console.log("OnChangeEndDate - s.detail...getDates", ds)

        this.$emit("refresh", { filter: { start_period: this.period.start.toISOString(), end_period: this.period.end.toISOString() } })
      },
      onHoverPopup2( index) {
        console.log(index)
      },
      onHoverPopup(rank, index, items) {
        console.log("onHoverPopup " + rank + "-" + index)
        this.comment = "id =" + index

        if(items.length == 0) return

        if (this.popupHoverSelection[rank * 100 + index] == undefined) {

          console.log("Create Hover ", rank, "-", index)

          // set the popover content elementpopover-default-pv2-28
          const $targetEl = document.getElementById('popover-hover-menu-' + this.id + '-' + rank + '-' + index);
          if ($targetEl == undefined) {
            console.log("Eroor targetEl ", rank, "-", index)
            console.log('popover-hover-menu-' + this.id + '-' + rank + '-' + index)
          }
          // set the element that trigger the popover using hover or click
          const $triggerEl = document.getElementById('popover-hover-button-' + this.id +  '-' + rank +'-' + index);
          if ($triggerEl == undefined) {
            console.log("Eroor triggerEl ", rank, "-", index)
            console.log('popover-hover-button-' + this.id  + '-'+ rank + '-' + index)
          }
          // options with default values
          const options = {
            placement: 'bottom',
            triggerType: 'hover',
            offset: 10,
            onHide: () => {
              console.log('popover is shown');
            },
            onShow: () => {
              console.log('popover is hidden');
              console.log(this)
            },
            onToggle: () => {
              console.log('popover is toggled');
            },
          };

          // instance options object
          const instanceOptions = {
            id: 'popover-hover-menu-' + this.id + rank + '-' + "-" + index, //'popover-hover',
            override: true
          };

          this.popupHoverSelection[index] = new Popover($targetEl, $triggerEl, options, instanceOptions);


        }
        else
          this.popupHoverSelection[rank * 100 + index].show()


      },

      OnSelectWeek() {
        if (this.dropdownWeekSelection == undefined) {
          const dateRangePickerEl = document.getElementById('my-week-picker');
          this.dropdownWeekSelection = new DateRangePicker(dateRangePickerEl, {
            // options
            dayOfWeek: 1,
            todayBtn: true,
            onHide: () => {
              console.log('OnSelectWeek has been hidden');
            },
            onShow: () => {
              console.log('OnSelectWeek has been shown');
            },
            onToggle: () => {
              console.log('OnSelectWeek has been toggled');
            },
          });
          console.log("========================>", this.dropdownWeekSelection.datepickers.length)
          this.dropdownWeekSelection.datepickers[0].show()
          //  this.dropdownWeekSelection.toggle()
        }
        else
          this.dropdownWeekSelection.datepickers[0].show()
      },


      onOpenDatePicker() {

      },


      onWeekSelection() {

        if (this.dropdownWeekSelection == undefined) {



          const $targetEl = document.getElementById('dateRangeDropdown');

          // set the element that trigger the dropdown menu on click
          const $triggerEl = document.getElementById('dateRangeButton');

          // options with default values
          const options = {
            placement: 'bottom',
            triggerType: 'click',
            offsetSkidding: 0,
            offsetDistance: 10,
            delay: 300,
            ignoreClickOutsideClass: false,
            onHide: () => {
              console.log('dropdown has been hidden');
            },
            onShow: () => {
              console.log('dropdown has been shown');
            },
            onToggle: () => {
              console.log('dropdown has been toggled');
            },
          };

          const instanceOptions = {
            id: 'dateRangeDropdown',
            override: true
          };
          this.dropdownWeekSelection = new Dropdown($targetEl, $triggerEl, options, instanceOptions);

          this.dropdownWeekSelection.show()
        }


      },
      onSearchSuggestionItemSelected(search) {
        this.globalQuery = search.reference
        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { reference: search.reference }, sort: this.sortAttribut, order: this.sortOrder })

        this.$nextTick(() => {
          console.log("Hide dropDown")
          this.closeSearchBar()
        });
      },


      onSearchLastestItemSelected(search) {

        //   this.$nextTick(() => {

        this.closeSearchBar()
        //   });


        //  this.globalQuery = search.name



        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: search.name }, sort: this.sortAttribut, order: this.sortOrder })
        return true;

      },

      onSearchItemSelected(search) {
        this.globalQuery = search.reference
        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { reference: search.reference }, sort: this.sortAttribut, order: this.sortOrder })

        this.$nextTick(() => {
          console.log("Hide dropDown")
          this.closeSearchBar()
        });
      },

      onEraseSearch(e) {

        e.preventDefault()
        this.globalQuery = ""

        this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: this.globalQuery }, sort: this.sortAttribut, order: this.sortOrder })

      },

      closeSearchBarKeyup(e) {

        if (e.key == 'Enter') {
          console.log(" closeSearchBarKeyup")
          this.closeSearchBar()

          this.$emit("refresh", { page: 1, collection: this.config.id, offset: 0, limit: MAX_ITEMS, filter: { q: this.globalQuery }, sort: this.sortAttribut, order: this.sortOrder })
          return;
        }

        this.$emit("search", { collection: this.config.id, offset: 0, limit: 5, filter: { q: this.globalQuery } })



        //
      },

      closeSearchBar() {
        console.log(" this.dropdownSearch.hide() 4")
        if (this.dropdownSearch) {
          this.dropdownSearch.hide()
          this.dropdownSearch = null;
        }
      },


      openSearchBar() {

        console.log("\topenSearchBar ")
        const options = {
          placement: 'bottom',
          triggerType: 'click',
          offsetSkidding: 0,
          offsetDistance: 0,
          delay: 300,
          onHide: () => {
            return true;
          },
          onShow: () => {
            return true;
          },
          onToggle: () => {
            return true;

          }

        };
        if (this.dropdownSearch == undefined) {
          const $targetEl = document.getElementById("mega-menu-dropdown");
          const $triggerEl = document.getElementById("mega-menu-dropdown-button");
          this.dropdownSearch = new Dropdown($targetEl, $triggerEl, options);


          console.log("this.dropdownSearch.show 222")

          //this.dropdownSearch.show()
        }

      }
      ,
      imagePath(item) {


        let path = item?.url

        if (path == undefined) return this.blank

        // if (path.url == undefined) return this.blank

        if (path.indexOf("http") == 0) {
          return path + "?icon=true";
        }

        if (path.indexOf('data:') == 0) return path;


        return this.host_media + path + "?icon=true&test=1";
      },

      onSearchClose: function () {
        this.isExtended = false;

      },
      onSeachFocus: function (e) {

        console.log("onSeachFocus")
        console.log(e)
        this.openSearchBar()

        this.isExtended = true;
        return true;
      },

      onSearchInput: function (/*key*/) {

        this.isExtended = true;
      },


      displaySeachValue: function (item) {
        if (item) return item.reference;
        return ''
      },
      OnClickSearch: function () {
        console.log("onClickSearch")
        this.openSearchBar()

        this.isExtended = false;
        return true;
      },

      onBack: function () {

        let url = this.$route.query.back;
        if (this.config && this.config.options && this.config.options.displayBack != false) {
          url = this.config.options.urlBack;
        }
        window.location = url
        // this.$router.push(url)
      },
      getLabel: function (col) {

        if (col?.buttonName != undefined) {
          if (col?.buttonName[this.lg] != undefined)

            return col?.buttonName[this.lg]
        }


        if (col.action == "download") return "Télécharger"; //this.common_labels['LABEL_DOWNLOAD'][this.lg]
        if (col.action == "edit") return this.common_labels['LABEL_EDIT'][this.lg]
        if (col.action == "qrcode") return this.common_labels['LABEL_VIEW_QRCODE'][this.lg]
        if (col.action == "view") return this.common_labels['LABEL_VIEW'][this.lg]
        if (col.action == "template") return this.common_labels['LABEL_EDIT'][this.lg]
        if (col.action == "navigate") return "Voir le produit";
        return "?????"

      },
      onButtonNumber: function (page) {

        if (page == "+") {

          //   let n = "(" +   parseInt(this.max_page) + "=" + (parseInt(this.current_page) +1) + ")/2 = "+ (( parseInt(this.max_page) -  ( parseInt(this.current_page) +1)) /2)
          this.current_page = this.current_page + parseInt((parseInt(this.max_page) - (parseInt(this.current_page) + 1)) / 2)
          this.start_page = this.current_page


        }
        else
          if (page == "-") {
            this.current_page = this.current_page - (parseInt(parseInt(this.current_page) / 2))
            this.start_page = this.current_page


          }
          else {



            this.current_page = page;
            this.start_page = page

            /*
            
                                    if (page == 1) {
                                        this.current_page = 1;
                                        this.start_page = 1
                                    }
                                    else {
                                        this.current_page = (this.start_page - 1) + page;
                                        this.start_page = this.current_page - 1
                                    }
            
            */

          }
        /*   if (this.current_page == this.max_page) {
               this.start_page = this.current_page - 2
 
           }
*/

        this.$emit("refresh", { page: this.current_page, collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters })


      },
      badgeColor(record, col) {

        let v = this.displayValue(record, col, false, false)
        v = v.toString();
        if (v == '') v = 'default'


        if (col['colors'] == undefined) return 'bg-gray-100 text-gray-800 border-gray-400'


        if (col['colors'][v.toString()] == 'red') return 'font-bold bg-red-100 text-red-800 border-red-400'
        if (col['colors'][v.toString()] == 'orange') return 'font-bold bg-orange-100 text-orange-800 border-orange-400'
        if (col['colors'][v.toString()] == 'green') return 'font-bold bg-green-100 text-green-800 border-green-400'
        return 'font-bold bg-gray-100 text-gray-800 border-gray-400'

      },
      getImageTitleData(record, col) {

        //  let v1 = col.data;
        //  v1 = this.displayHandlebar(record, col, true, 'data');
        // if (record[v1] == undefined) return [];

        //    console.log("col.data --------------------------------------")
        //    console.log(col.data)

        //    console.log("record --------------------------------------")
        //    console.log(record)

        //    console.log("v1 --------------------------------------")
        //    console.log(v1)
        //    console.log("record --------------------------------------")
        //    console.log(record)
        //    console.log("performedBy --------------------------------------")
        //    console.log(record.performedBy)

        /*    if (Array.isArray(record)) {
 
 
                let components = [];
                for (let i = 0; i < record[v1].length; i++) {
                    let url = this.displayHandlebar(record[v1][i], col, true, 'img');
                    let title = this.displayHandlebar(record[v1][i], col, true, 'data');
                    let subtitle = this.displayHandlebar(record[v1][i], col, true, 'subdata');
 
 
 
                    components.push(
                        {
                            title: title,
                            subtitle: subtitle,
                            url: "https://files.b4food.io/b4food/images/" + url
                        })
                }
                console.log("/////////////////////////////////////////////////////")
                console.log(components)
                return components
            }*/

        let title = this.displayHandlebar(record, col, true, 'data');
        let subtitle = this.displayHandlebar(record, col, true, 'subdata');

        let default_image = col.image_default;


        if (default_image == undefined) default_image = blank;

        let url = this.displayHandlebar(record, col, true, 'image_url');



        if (url == '-') {


          url = default_image;

        }


        return [{
          title: title,
          subtitle: subtitle,
          url: "https://files.b4food.io/b4food/images/" + url
        }]

      },
      changeSelect(event, value) {
        //this.filters = {}
        this.filters[value] = event.target.value

        this.$emit("refreshTable", { value: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })

      },
      selectItemType(value) {


        this.category = value;
        this.globalQuery = "";
        this.$emit("selectTab", value)
        //  this.filters = {}
        //  this.filters['category'] = value;
        //   this.$emit("refreshTab", { value: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters, level: 2 })

      },

      sortCol(col) {
        if (this.sortColId == col.id) {
          if (this.sortOrder == 'up') this.sortOrder = 'down'
          else this.sortOrder = 'up'

          this.$emit("refresh", { collection: this.config.id, offset: (this.current_page), limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })
          return;
        }

        this.sortOrder = 'down'
        this.sortColId = col.id
        this.sortAttribut = col.sort

        this.$emit("refresh", { collection: this.config.id, offset: (this.current_page), limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })
      },
      checkVisibility(col, record) {

        if (col.condition != undefined) {

          let show = false;

          for (let i = 0; i < col.condition.length; i++) {


            let keys = Object.keys(col.condition[i])
            for (let j = 0; j < keys.length; j++) {
              if (record[keys[j]] == col.condition[i][keys[j]]) {
                show = true;
              }
            }


          }
          return show;
        }

        return true;
      },

      async onEditButton(col, record) {
        event.stopPropagation();

        if (col.action == "template") {
          //    let url = "http://localhost:3000/design/" + record._id
          let url = "https://editor.b4food.io?id=" + record._id

          window.open(url, "template-editor")

          return;
        }


        if (col.action == "navigate") {

          window.location = "/admin/views/products/" + record.product_id + "?back=/admin/views/data-errors"
          return;
        }

        if (col.action == "download") {

          if (col.data == "file_fr.url")
            window.open("https://file-storage-ten.vercel.app/b4food/files/64ad21bb9b5bbf445ad621ab")

          if (col.data == "file.url") {

            let file = record['file'].url;


            this.$emit("download", { file: file, name: record.name })

            // await this.downloadPDF(file, record.name)
            //  let result = this.buildValue(col.data, record, "")
            //  result = result.toString()

            //downloadFile()
          }

          return false;
        }
        if (col.action == "qrcode") {

          return false;
        }

        if (col.buttonURI) {
          let url = col.buttonURI.replace(":id", record._id);
          url = url.replace(":reference", record.reference);

          if (col.backInUrl) {
            url = url + "&back=/admin/views/enrs"

          }
          this.$router.push(url)
          return;
        }


        //  this.$router.push("/admin/flows/" + record.id)
      },
      onClickCheck(event, item) {
        event.stopPropagation();
        this.selected.push(item._id)
      },
      onButtonNext() {

        if ((this.current_page + 2) > this.max_page) {
          this.current_page = this.max_page;
          this.start_page = this.current_page - 2;
        }
        else {
          this.current_page = this.current_page + 1;
          this.start_page = this.current_page - 1;
        }



        if (this.category != undefined) this.filters['category'] = this.category;

        this.$emit("refresh", { page: this.current_page, collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })
      },
      onButtonPrevious() {

        if (this.current_page < 3) {
          this.current_page = 1;
          this.start_page = 1;
        }
        else {
          this.current_page = this.current_page - 1;
          this.start_page = this.current_page - 1;
        }




        if (this.category != undefined) this.filters['category'] = this.category;
        this.$emit("refresh", { page: this.current_page, collection: this.config.id, offset: (this.current_page - 1) * MAX_ITEMS, limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })

      },
      onClickBreadCrumb(/*val*/) {

      },

      onClickDelete() {
        this.$emit("delete", { collection: this.config.id, selected: [] })
      },

      onClickDuplicate() {


        if (this.selected.length == 1)
          this.$emit("duplicate", this.selected[0])
        else
          alert("Error on duplicate")
      },

      onClickRefresh() {

        this.$emit("refresh", { collection: this.config.id, offset: (this.current_page), limit: MAX_ITEMS, filter: this.filters, sort: this.sortAttribut, order: this.sortOrder })

      },
      onClickExport() {

        this.$emit("export", { collection: this.config.id })
      },
      onClickAdd() {

        this.$emit("add", { collection: this.config.id })
      },
      onClickUp() {
        this.$emit("up", { collection: this.config.id })
      },
      onRowClick(record) {

        this.$emit("rowClick", { collection: this.config.id, record: record._id, body: record })
      },

      manageVariable(v, d, v_d) {

        if (Array.isArray(v)) {
          let r = ""
          for (let i = 0; i < v.length; i++) {
            r = r + this.manageVariableSub(v[i], d)
          }
          if (r == undefined) return v_d;
          return r;
        }
        let val = this.manageVariableSub(v, d)
        if (val == undefined)
          if (v_d != undefined) return v_d
          else ""
        return val
      },
      manageVariableSub(v/*, d*/) {

        if (v == undefined) {
          return v;
        }
        if (v[0] == '@') {
          // if (d == undefined)  {}
          let a = v.replace('@', '')
          try {

            let p = 'd.' + a;

            let e = eval(p);

            if (e == undefined) e = undefined
            return e;
          }
          catch (e) {

            return '-'
          }
        }
        return v
      },
      buildValue(format, record, s) {

        for (let i = 0; i < format.length; i++) {

          let el = format[i]
          let newclass = el.class;

          if (el.mapping) {
            let m = this.manageVariable(el.data, record);
            newclass = newclass + " " + el.mapping[m].class;
          }
          let tag = el.tag
          if (tag == undefined) tag = "span"

          if (newclass == undefined) newclass = ""

          s = s + '<' + tag + ' class="' + newclass + '">'

          if (el.children) {
            s = s + this.buildValue(el.children, record, s)
          }
          else
            if (el.mapping) {
              let m = this.manageVariable(el.data, record);

              if (el.mapping[m])
                s = s + el.mapping[m][this.lg]
              else
                s = s + m
            }
            else {

              let v = this.manageVariable(el.data, record, el.default)

              s = s + v
            }

          s = s + '</' + tag + '>'
        }


        return s;
      },
      buildAvatar(v1, v2, record) {

        if (Array.isArray(record[v1])) {
          return v1;
        }
        return v1;
        /*
                        let a1 = this.manageVariable(v1, record);
                        let a2 = this.manageVariable(v2, record);
        
                        if ((a1 == undefined) || (a1 == ""))
                            a1 = "";
                        else
                            a1 = a1[0].toUpperCase();
        
                        if ((a2 == undefined) || (a2 == ""))
                            a2 = "";
                        else
                            a2 = a2[0].toUpperCase();
        
                        if (a1 == "" && a2 == "") a1 = "-";
        
                        return a1 + a2;*/
      },
      displayHandlebarText(record, col, map, att) {

        let visible = this.displayHandlebar(record, col, map, 'visible')
        if (visible == null) return ""
        if (visible == "null") return ""
        if (visible == "-") return ""
        if (visible == "--") return ""
        let title = this.displayHandlebar(record, col, map, att)



        return title

      },


      displayHandlebar(record, col, map, att) {


        if (col[att] == undefined) return "";


        const template = Handlebars.compile(col[att]);
        let label = (template(record));

        //Voir pour le defaut
        if (label == "") {

          if (col.default == undefined) return "--"
          if (col.default[this.lg] == undefined) return "--"
          return col.default[this.lg]
        }

        if (map == false) return label;

        if (col.map == undefined) return label.toString();
        if (col.map[this.lg] == undefined) return label.toString();
        if (col.map[this.lg][label] == undefined) return label.toString();
        //  return col.map[this.lg][label].toString();

        return col.map[this.lg][label]

      },
      displayHandlebarDateTime(record, col, map, att) {


        let date = this.displayHandlebar(record, col, map, att)
        if (date == undefined) return "";
        if (date == "") return "";
        if (date == "null") return "";

        if (date == "--") return "";

        let d = new Date(date)
        let m = ("0" + (d.getMonth() + 1)).slice(-2)
        let day = ("0" + (d.getDay() + 1)).slice(-2)
        let hour = ("0" + (d.getHours() + 1)).slice(-2)
        let minute = ("0" + (d.getMinutes() + 1)).slice(-2)
        if (d.getFullYear() == undefined) return ""

        if (m == undefined) return ""

        if (day == undefined) return ""


        return (day + "/" + m + "/" + d.getFullYear() + " à " + hour + ":" + minute);





      },

      displayHandlebarDate(record, col, map, att) {


        let date = this.displayHandlebar(record, col, map, att)
        if (date == undefined) return "";
        if (date == "") return "";
        let a = date.split("T")

        let b = a[0].split("-");

        return b[2] + "/" + b[1] + "/" + b[0]


      },


      indicatorColorHandlebar(record, col) {

        let v = this.displayHandlebar(record, col, false, 'data')
        v = v.toString();
        if (v == '') v = 'default'


        if (col['colors'] == undefined) return 'bg-gray-300'
        if (col['colors'][v.toString()] == 'purple') return '  bg-purple-300'


        if (col['colors'][v.toString()] == 'red') return 'bg-red-500 '
        if (col['colors'][v.toString()] == 'orange') return ' bg-orange-500'
        if (col['colors'][v.toString()] == 'green') return 'bg-lime-500 '
        return ' bg-gray-300 '

      },




      badgeColorHandlebar(record, col) {

        let v = this.displayHandlebar(record, col, false, 'data')
        v = v.toString();
        if (v == '') v = 'default'


        if (col['colors'] == undefined) return 'bg-gray-100 text-gray-800 border-gray-400'
        if (col['colors'][v.toString()] == 'purple') return 'font-bold bg-purple-100 text-purple-800 border-purple-400'


        if (col['colors'][v.toString()] == 'red') return 'font-bold bg-red-100 text-red-800 border-red-400'
        if (col['colors'][v.toString()] == 'orange') return 'font-bold bg-orange-100 text-orange-800 border-orange-400'
        if (col['colors'][v.toString()] == 'green') return 'font-bold bg-green-100 text-green-800 border-green-400'
        return 'font-bold bg-gray-100 text-gray-800 border-gray-400'

      },
      displayMappedValue(record, col) {
        let v = this.displayValue(record, col, false)
        return v;
      },


      begEndWeek(date) {


        let n = 7 - date.getDay();

        let end = new Date(date)
        end.setDate(end.getDate() + n);


        let beg = new Date(end.toISOString())
        beg.setDate(beg.getDate() - 7 * 5 + 1);






        return { b: beg, e: end }
      },
      displayValue(record, col, simple = false, map = true) {


        if (col.format != undefined) {
          let result = this.buildValue(col.format, record, "")
          result = result.toString()
          return result;
          //A revoir car il y a le formattage, a faire avant
          //    if (map == false) return result;
          //    if (col.map == undefined) return result;
          //    if (col.map[this.lg] == undefined) return result;
          //    if (col.map[this.lg][result] == undefined) return result;
          //    return col.map[this.lg][result].toString();

        }

        if (record[col.data] == null) {
          if (col.default != undefined) {

            if (map == false) return "";
            if (col.default[this.lg] == undefined) return col.default.toString();

            return col.default[this.lg].toString();
          }



          if (simple == true)
            return "<span class='text-red-900 font-bold'><i class='fa-solid fa-triangle-exclamation mr-2'></i>Info non disponible</span>"
          else
            return " - "

        }

        if (col.type == "date-time") {
          if (record[col.data] == undefined) return "";
          var myDate = new Date(record[col.data]);
          const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
          let date = myDate.toLocaleString('fr-FR', options);
          return date
          //return  date[0].replace("T"," ")
        }

        if (col.type == "date") {


          let date = record[col.data].split("T")
          if (date.length != 2) {
            date = record[col.data].split(" ")
          }
          return date[0];
        }


        if (map == false) return record[col.data];

        if (col.map == undefined) return record[col.data].toString();
        if (col.map[this.lg] == undefined) return record[col.data].toString();
        if (col.map[this.lg][record[col.data]] == undefined) return record[col.data].toString();
        return col.map[this.lg][record[col.data]].toString();

      }
    }
  }
</script>