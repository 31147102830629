<template>
  <!-- <TopNav class="hidden"/>-->
  <SideNav />
  <div
    v-if="isMultiSite"
    class="flex flex-col w-full  "
  >
    <div class=" w-full  bg-gray-800">
      <div
        class="inline-flex rounded-md shadow-sm"
        role="group"
      >
        <button
          type="button"
          class="px-4 pb-1 pt-2 text-xs font-medium     cursor-pointer  text-white border-b-4 "
          :class="{'   font-bold  border-green-300': (selected==0), 'border-gray-800    hover:border-orange-500 ' : (selected!=0)}"
          @click="selectSite(0,'all')"
        >
          Tous les sites
        </button>
        <button
          v-for="(entity, index) in user?.tenant?.entities"
          :key="entity.id"
          type="button"
          class="px-4 pb-1 pt-2  text-xs font-medium  text-white  border-b-4 cursor-pointer"
          :class="{'   font-bold  border-green-300': (selected== (index+1)), ' border-gray-800  hover:border-orange-500 ' : (selected!= (index+1))}"
          @click="selectSite(index + 1, entity._id)"
        >
          {{ entity.name }}
        </button>
      </div>
    </div>
    <router-view
      class="w-full"
      :refresh="timestamp"
    />
  </div>
  <router-view
    v-if="!isMultiSite"
    class="w-full"
    :refresh="timestamp"
  />

  <!-- drawer init and toggle -->
</template>

<script>
  // import TopNav from '@/components/TopNav.vue'
  import SideNav from '@/components/SideNav.vue'
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: 'AdminLayout',
    data() {
      return {
        selected: 0,
        timestamp: null
      }
    },

    computed: {
      isMultiSite: function () {

        return (this.user?.tenant?.entities?.length > 0)

      },

      ...mapGetters({ user: "StateUser" }),
      isLoggedIn: function () {
        return this.$store.getters.isAuthenticated;
      },
    },
    watch: {
      'user.entity_id': function () {
        this.timestamp = (new Date()).toISOString()
      }
    },
    mounted() {
      if (this.user?.entity_id == "66a4a19c8d43c894c147758a") this.selected = 1;
      if (this.user?.entity_id == "66a4a1cb8d43c894c147758b") this.selected = 2;

      this.timestamp = (new Date()).toISOString()
    },
    methods:
    {
      ...mapActions(["ChangeEntity"]),
      selectSite(n, id) {
        this.selected = n
        this.ChangeEntity(id)
      }
    },
    components: {
      SideNav /*, TopNav*/
    }
  }
</script>