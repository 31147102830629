<template>
  <div
    v-if="$route?.params?.rid != 'new'"
    class="flex items-center justify-center w-full relatives "
  >
    <div
      v-if="config.mode == 'register'"
      class="w-4/12  w-full flex flex-col "
    >
      <div class="mt-2 mb-2 w-full bg-white flex flex-row items-center">
        <button
          type="button"
          class="  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          @click="onRefreshCode"
        >
          <i class="fa-solid fa-refresh  mr-2" />

          Générer
        </button>
      </div>
    </div>
    <div
      v-if="config.mode != 'register'"
      class="w-6/12  w-full flex flex-col "
    >
      <div class="mt-2 mb-2 w-full bg-white flex flex-row items-center">
        <label
          for="qr_ean"
          class="w-full text-left  mt-2  mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ labels['GS1_EAN'][lg] }}
        </label>
        <label class="w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
          <input
            id="qr_ean"
            v-model="output.qr_ean"
            type="checkbox"
            value=""
            class="sr-only peer"
            @change="onChange('qr_ean')"
          >
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
          />
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
        </label>
      </div>

      <div class="mt-2 mb-2 w-full bg-white flex flex-row items-center">
        <label
          for="qr_batch"
          class="w-full text-left  mt-2  mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ labels['GS1_BATCH'][lg] }}
        </label>
        <label class="w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
          <input
            id="qr_batch"
            v-model="output.qr_batch"
            type="checkbox"
            value=""
            class="sr-only peer"
            @change="onChange('qr_batch')"
          >
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
          />
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
        </label>
      </div>

      <div class="mt-2 mb-2 w-full bg-white flex flex-row items-center">
        <label
          for="qr_serial"
          class="w-full text-left  mt-2  mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ labels['GS1_SERIAL'][lg] }}
        </label>
        <label class="w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
          <input
            id="qr_serial"
            v-model="output.qr_serial"
            type="checkbox"
            value=""
            class="sr-only peer"
            @change="onChange('qr_serial')"
          >
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
          />
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
        </label>
      </div>

      <div class="mt-2 mb-2 w-full bg-white flex flex-row items-center">
        <label
          for="qr_dluo"
          class="w-full text-left  mt-2  mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ labels['GS1_DLUO'][lg] }}
        </label>
        <label class="w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
          <input
            id="qr_dluo"
            v-model="output.qr_dluo"
            type="checkbox"
            value=""
            class="sr-only peer"
            @change="onChange('qr_dluo')"
          >
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
          />
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
        </label>
      </div>


      <div class="mt-2 mb-2 w-full bg-white flex flex-row items-center">
        <label
          for="qr_weight"
          class="w-full text-left  mt-2  mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          {{ labels['GS1_WEIGHT'][lg] }}
        </label>
        <label class="w-auto relative inline-flex items-center mt-2  mb-2 cursor-pointer">
          <input
            id="qr_weight"
            v-model="output.qr_weight"
            type="checkbox"
            value=""
            class="sr-only peer"
            @change="onChange('qr_weight')"
          >
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
          />
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
        </label>
      </div>
    </div>
    <div class="w-6/12 w-full bg-white flex justify-center">
      <div class=" bg-white  w-full flex flex-col items-center justify-center">
        <span
          v-if="config.mode != 'register'"
          class="  px-6 w-full"
        >
          {{ url }}
        </span>

        <vue-qr
          logo-scale="0.3"
          dot-scale="0.9"
          class="  h-96 w-96 bg-black   "
          :components="options"
          :logo-src2="logo"
          :text="url"
          :callback="test"
          qid="testid"
        />
        <img
          v-if="config.mode != 'register'"
          class="h-12"
          :src="logo1"
        >
      </div>
    </div>
  </div>
</template>
<script>
    import labels from "@/assets/lg/component_qrcode.json"
    import logo from "@/assets/img/qr2.png"
    import logo1 from "@/assets/img/qr1.png"
    import vueQr from 'vue-qr/src/packages/vue-qr.vue'

    import axios from "axios";




    export default {
        name: 'ContentQRCode',
        components: {
            vueQr
        },
        props: {
            source: Object,
            value: Object,
            config: Object
        },
        //compute: {
        //   url: function () {
        //       if (this.value == undefined) return ""
        //       return this.value.name
        //       // return this.source.qrcode_ean.toString()
        //   }
        //},
        data() {
            return {
                output: {},
                options: {
                    data: {
                        scale: 0.8,
                    },
                    timing: {
                        scale: 0.5,
                        protectors: true,
                    },
                    alignment: {
                        scale: 0.5,
                        protectors: true,
                    },
                    cornerAlignment: {
                        scale: 0.5,
                        protectors: true,
                    },
                },
                logo: logo,
                logo1: logo1,
                labels: labels,
                lg: 'FR',
                code: "",
                url: '',
                size: 400,
            }
        },
        watch: {
            '$route.params.rid': function () {

                this.onRefreshCode()
                //   this.output = val;
            },
            '$route.params': function () {

                this.onRefreshCode()
                //   this.output = val;
            }
        },
        mounted() {
            this.output.qr_ean = true;
            this.output.qr_batch = true;
            this.output.qr_serial = true;
            this.output.qr_dluo = true;
            this.output.qr_weight == true;

            this.onRefreshCode()
        },
        methods: {

            onRefreshCode(/*dataUrl, id*/) {


                let id = this.$route?.params?.rid

                this.code = id;
                axios
                    .get("/api/b4food/tablet/v2/consoles/" + id + "/token", {
                        headers: {
                            "x-access-token": this.user?.token,
                            "content-type": "multipart/form-data"
                        },
                    })
                    .then((res) => {
                        console.log(res)
                        this.url = res.data.token

                    })

            },
            onChange: function () {
                this.url = 'https://b4f.biz';

                if (this.output.qr_ean == true) {
                    this.url += '/01/' + this.value.item_gtin
                }

                if (this.output.qr_batch == true) {
                    this.url += '/10/23041VPB'
                }

                if (this.output.qr_serial == true) {
                    this.url += '/21/40'
                }

                if (this.output.qr_dluo == true) {
                    this.url += '/15/230416'
                }

                if (this.output.qr_weight == true) {
                    this.url += '/3103/00' + this.value.item_weight
                }
                //17) expired date
            }
        }
    }
</script>