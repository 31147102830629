<template>
  <div class="relative overflow-y-auto bg-gray-50 lg:ml-16 dark:bg-gray-900">
    <div class="  dark:border-gray-50 mt-2">
      <h2 class="hidden px-8 text-left mb-2 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
        {{ displayTitle() }}
      </h2>
      <h4 class="hidden px-8 text-left mb-2 text-xl tracking-tight font-bold text-gray-600 dark:text-white">
        {{ displaySubTitle() }}
      </h4>
    </div>

    <WizardComponent
      :config="config"
      :record="record"
      :template="template"
      :custom-action="customAction"
      :title="displayTitle()"
      :subtitle="displaySubTitle()"
      :countries="countries"
      @generate="OnGenerateSheet"
      @value-changed="onValueChanged"
      @display="OnDisplaySheet"
      @change-view="onChangeView"
      @save="onSave"
      @delete="onDelete"
      @cancel="onCancel"
      @select-image-modal-open="onSelectImageModalOpen"
    />

    <SelectProductType
      @close="OnSelectImageModalClose"
      @select-image="onSelectImage"
      @add-image="OnAddImage"
    />
    <GenerateSheetModal
      @close="OnGenerateSheetClose"
      @generate="OnGenerateSheetGenerate"
      @confirm="OnGenerateSheetConfirm"
    />
  </div>
</template>
<script>
    import WizardComponent from '@/components/WizardComponent.vue'
    import service from "@/services/FullService.vue"
    import SelectProductType from '@/components/modals/SelectImageModal.vue'
    import GenerateSheetModal from '@/components/modals/GenerateSheetModal.vue'

    import { unflatten } from 'flat'
    import { Modal } from 'flowbite';
    import { mapGetters } from "vuex";
    export default {
        name: 'WizardView',
        components: {
            WizardComponent, SelectProductType, GenerateSheetModal
        },
        data() {
            return {
                isLoading: false,
                config: {},
                record: {},
                countries: [],
                currentImageField: null,
                template: {},
                customAction: null,
                surveyModal: null,
                generateSheetModal: null,
                lg: "FR",
                title: "",
                button: ""
            }
        },

        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch:
        {

            config: function (val) {

                if (val) {

                    if (val.title) this.title = val.title[this.lg];
                    if (val.button) this.button = val.button[this.lg];
                    //  this.loadPage()
                }
            }
        },
        async mounted() {


            if (this.user == undefined) {
                //this.$router.push("/login");
                window.location = "/login"
                return;
            }
            //if (this.$route.params.id == "batches") {
            //    //this.$router.push("/login");
            //    window.location = "/admin/batches/" + this.$route.params.rid
            //    return;
            // }

            await this.loadCountries()
            const modalEl = document.getElementById('select-image-modal');
            this.surveyModal = new Modal(modalEl, {
                placement: 'center'
            });

            const modalEl2 = document.getElementById('generate-sheet-modal');
            this.generateSheetModal = new Modal(modalEl2, {
                placement: 'center'
            });


            await this.loadPage()

        },
        methods: {
   


            async onValueChanged(event) {
                this.record[event.id] = event.value;


                if (event.id == "supplier_id") {
                    //product-suppliers
                    let collection = "data-sources/" + "66a1385f3cedbc009d93e9ab" + "/records"
                    let items = await service.getData(collection, this.user?.token, Object.assign({}, { limit: 1000, offset: 0 }, { supplier_id: event.value }));
                    this.record['items'] = items?.records;
                }

                if (event.id == "team_id") {
                    //On change le job_ud si changement de team_id

                    if (event.mode == 0) this.record.job_id = ""

                    this.customAction =
                    {
                        timestamp: new Date(),
                        attribut: "job_id",
                        value: ""
                    }
                }


            },
            async loadCountries() {
                let url = "data-sources/66bca5def254afd9ae336ac8/records"
                let records = await service.getData(url, this.user?.token, { limit: 300 });

                for (let i = 0; i < records.records.length; i++) {
                    records.records[i].flag = "fi-" + records.records[i].iso_2?.toLowerCase()
                }

                this.countries = records.records
            },


            OnAddImage() {

            },
            displayTitle() {
                if (this.template == undefined) return ""
                if (this.template.title == undefined) return ""
                if (this.lg == undefined) return ""


                if ((this.record?.name != undefined) && (this.record?.name != "")) return this.record?.name
                return this.template.title[this.lg]

            },
            displaySubTitle() {
                if (this.template == undefined) return ""
                if (this.template.subtitle == undefined) return ""
                if (this.lg == undefined) return ""



                return this.template.subtitle[this.lg].replace(":id", "")

            },
            OnGenerateSheetOld() {
                //NA this.generateSheetModal.show()
            },
            OnDisplaySheet() {
                //NA  let url = "/admin/pdf/display/" + this.$route.params.rid
                //NA window.location = url;

            },
            OnGenerateSheet(/*data*/) {

                /* 
                 let langage = "fr"
                 let market = "EU"
  
                 if (data.market == "eu-en") {
                     langage = "en"
                     market = "EU"
 
                 }
 
                 if (data.market == "us-en") {
                     langage = "en"
                     market = "US"
 
                 }
 
                 if (data.market == "au-en") {
                     langage = "en"
                     market = "AU"
 
                 }
 
                 let url = "/admin/pdf/" + this.$route.params.rid + "?market=" + market + "&langage=" + langage
                 window.location = url;
                 */
                // window.open(url, this.$route.params.rid)

                //this.$router.push(url)

                // window.open("https://seashell-app-rrlf8.ondigitalocean.app/generate/" + this.$route.params.rid+"?market=" +data.market, this.$route.params.rid);
            },

            OnGenerateSheetClose() {
                this.generateSheetModal.hide()
            },
            OnGenerateSheetGenerate() {
                this.generateSheetModal.hide()
            },
            OnGenerateSheetConfirm() {
                this.generateSheetModal.hide()
            },
            onSelectFile(image) {


                this.record[this.currentImageField] =
                {
                    "name": image.name,
                    "url": "https://files.b4food.io/b4food/files/" + image.id
                }

                this.surveyModal.hide();
            },
            onSelectImage(image) {


                this.record[this.currentImageField] =
                {
                    "name": image.name,
                    "url": "https://file-storage-ten.vercel.app/b4food/images/" + image.id
                }

                this.surveyModal.hide();
            },
            onSelectImageModalOpen: function (item) {
                this.currentImageField = item;

                this.surveyModal.show();

            },
            OnSelectImageModalClose: function () {

                this.surveyModal.hide();
            },
            onCancel() {

                if (this.template) {
                    if (this.template.back) {

                        if (this.template.back.link) {
                            window.location = this.template.back.link;
                            return;
                        }

                    }
                }


                let url = "/admin/views/" + this.$route.params.id;
                window.location = url;
            },
            async onDelete(item) {

                let collection = this.$route.params.id;
                //let response =
                await service.deleteRecord(collection, this.user?.tokenn, item._id)

                this.loadPage()
                this.onCancel()
            },
            async onSave(item) {
 
                if ((this.$route.params.rid == undefined) || (this.$route.params.rid == 'new')) {

                    let collection = this.$route.params.id
                    if (this.template.endpoint != undefined)
                        collection = this.template.endpoint
                    if (collection != "select-values") {
                        if (this.$route.query.template) {
                            item.category = this.$route.query.template;
                        }
                        else {
                            //A revoir
                            item.category = this.$route.params.id
                        }
                    }

                    collection = "data-sources/" + this.template.source_id + '/records'


                    let response = await service.addRecord(collection, this.user?.token, item)

                    //  let response = await service.addRecord(collection, this.user?.token, item)
                    if (response != undefined) {
                        if (response.insertedId != undefined) {
                            //  this.$router.push("/admin/views/" + this.$route.params.id + "/" + response.insertedId)

                            window.location = "/admin/views/" + this.$route.params.id + "/" + response.insertedId
                        }

                        else {
                            // this.$router.push("/admin/views/" + this.$route.params.id + "/" + response._id)
                            window.location = "/admin/views/" + this.$route.params.id + "/" + response._id
                        }
                    }



                }
                else {

                    let itemunflatten = unflatten(item)
                    //let response =
                    await service.updateRecord(this.$route.params.id, this.$route.params.rid, this.user?.token, itemunflatten)

                }


            },


            async loadPage() {
                let category = ""
                let template = this.$route.params.id
                //if (this.$route.params.rid == 'new') 
                {

                    this.record = {
                        last_name: '************'
                    }
                    if (this.$route.query.template != undefined) category = this.$route.query.template

                }
                /*    else {
                
                           this.record = await service.getRecord(this.$route.params.id, this.$route.params.rid, this.user?.token)
     
                          if (this.record != undefined) category = this.record.category
                         if (this.record?.template != undefined) category = this.record.template
     
                         if (category == undefined) category = this.$route.query.template
     
                     }*/

                let newTemplate = template
                if (template == "batches") {
                    newTemplate = template + "-wizard"
                }


                if (template == "manufacturing-orders") {
                    newTemplate = template + "-wizard"
                }
                if (template == "inbound-shipments") {
                    newTemplate = template + "-wizard"
                }

               if (template == "actors") {
                   newTemplate = template + "-wizard"
             }


                console.log("WizardView 1", category)
                this.template = await service.getConfigurationDetails(newTemplate, category, this.user?.token)

                if (this.template == undefined) {
                    alert("NOK getConfigurationDetails =" + template + " -  " + category)
                    return;
                }
                if (this.template.default != null) {

                    this.record = this.template.default
                }

                this.isLoading = true;
                // batches-wizard
                console.log("WizardView 2", category)
                this.config = await service.getConfiguration(this.$route.params.id, "a", this.user?.token)




            },
            async onChangeView(item) {

                // this.config = await service.getConfiguration(item.collection, this.user?.token)
                console.log("WizardView 3", item.collection)

                let url = "/admin/views/" + item.collection + "/" + item.record;
                window.location = url
            }
        },
    
    }
</script>