<template>
  <aside
    id="sidebar-double"
    class="flex z-40 fixed top-0 left-0 h-full transition-transform -translate-x-full sm:translate-x-0 overflow-x-hidden overflow-y-hidden "
    aria-label="Sidebar"
  >
    <div
      class="  relative  overflow-x-hidden  z-30 py-5 w-12   bg-gray-800 border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 "
    >
      <ul class="  fixed top-2  h-16 w-12 ">
        <li class="py-2 px-1 ">
          <button
            v-if="user?.isAdmin==true"
            id="side-dropdown-tenant-button"
            aria-expanded="false"
            class="  flex  items-center   "
            type="button"
            @mouseover="onSelectMenu(null)"
          >
            <img
              v-if="currentTenant?.logo != undefined"
              :src="currentTenant.logo"
              class="rounded-sm h-auto w-full bg-white"
              alt="B4food.io"
            >
          </button>

          <div
            v-if="user?.isAdmin!=true"
            id="side-dropdown-tenant-div"
            aria-expanded="false"
            class="  flex  items-center   "
            type="button"
          >
            <img
              v-if="currentTenant?.logo != undefined"
              :src="currentTenant.logo"
              class="rounded-sm h-auto w-full bg-white"
              alt="B4food.io"
            >
          </div>
        </li>
      </ul>

      <ul class="fixed  top-16 bottom-24     overflow-x-hidden    overflow-y-hidden  flex flex-col items-end justify-center">
        <li
          v-for="item in config?.items"
          :key="item.id"
          class="cursor-pointer  w-full justify-center"
        >
          <div
            class="cursor-pointer  pt-2 pl-2 flex flex-col  w-10 h-10   xl:h-12 xl:w-12 items-center justify-center text-gray-400 hover:text-white   text-gray-400  transition duration-75 dark:text-gray-400 hover:text-gray-100 dark:hover:text-white hover:bg-gray-800  active:bg-green-600 "
            :class="{'bg-gray-800 text-white' :(item.id==currentitem.id)}"
            @click="onSelectMenu(item)"
            @mouseover="onSelectMenu(item)"
          >
            <i
              class="   w-6 h-6  lg:w-8 lg:h-8 xl:w-12 xl:h-12  transition duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white"
              :class="item.icon"
            />
          </div>
        </li>
      </ul>

      <ul class="fixed bottom-0  w-12  pb-2">
        <li
          class="py-1 text-gray-300 hover:text-white cursor-pointer flex justify-center items-center" 
          @click="onClickSignOut"
        >
          <i class="fa-solid fa-power-off h-6 w-8 align-center transition duration-75 " />
        </li>
        <li class="lg:py-1 h-12  ">
          <img
            :src="logo"
            class="h-auto w-12"
            alt="B4food.io"
          >
        </li>
        <li />
      </ul>
    </div>

    <div
      id="secondary-sidenav"
      class="hidden  bg-blue-100 overflow-y-auto relative pb-5 w-64 h-full bg-white bordere-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="h-24 flex items-center justify-center px-2 bg-gray-700  ">
        <span class="font-bold text-xl text-white">

          {{ currentitem?.name?.FR }}
        </span>
      </div>

      <ul
        :id="`dropdown-${currentitem?.id}`"
        class="pt-5 mt-5  px-3  space-y-2 bg-white border-t border-gray-100 dark:border-gray-700"
      >
        <li
          v-for="subitem in subitems"
          :key="subitem.id"
        >
          <div
            v-if="subitem.link != &quot;separator&quot;"
            class="cursor-pointer text-left flex items-center px-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
            @click="onSelectSubMenu(subitem)"
          >
            <div> {{ subitem.name ? subitem.name[lg]: '%%%' }} </div>
          </div>

          <div
            v-if="subitem.link == &quot;separator&quot;"
            class="cursor-none text-left flex items-center px-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
            @click="onSelectSubMenu(subitem)"
          >
            <hr class="h-1 border-t border-gray-200  w-full">
          </div>
        </li>
      </ul>
      <ul
        v-if="showDocumentationMenu"
        class="  pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700 h-max   items-center justify-start text-left"
      >
        <li>
          <a
            href="#"
            class="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group text-left"
          >
            <span class="ml-3">Documentation</span>
          </a>
        </li>

        <li>
          <a
            href="#"
            class="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group"
          >
            <span class="ml-3">Aide</span>
          </a>
        </li>
      </ul>
      <div class="hidden flex absolute right-2 bottom-2 z-20 justify-end w-full bg-white dark:bg-gray-800">
        <button
          id="show-secondary-sidenav-button"
          aria-controls="secondary-sidenav"
          type="button"
          class="inline-flex p-2 text-gray-500 rounded-full cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import {/* Dropdown,*/ Drawer } from 'flowbite';
    import service from "@/services/FullService.vue"

    import logo from "@/assets/img/favicon-64.png"


    export default {
        name: 'SideNav',
        props: {
            configNew: Object,
            records: Object
        },

        data() {
            return {
                lg: "FR",
                dropdownTenant: null,
                sideNav: [],
                showDocumentationMenu: true,
                tenants: [],
                logo: logo,
                subitems: [],
                tenantMenu: {},
                currentitem: {},
                loading2: false,
                mode: 'closed',
                currentTenant: {
                    "_id": null,
                    "name": null,
                    "logo": null
                },

                config: {}
            }
        },
    

        computed: {
            ...mapGetters({ user: "StateUser" }),
            isLoggedIn: function () {
                return this.$store.getters.isAuthenticated;
            },
        },
        watch: {

            'user.tenant_id': function () {

                this.loadMenu()
            }
        },
        mounted() {

            this.loadTenants();

            this.loadMenu();
            var barButtonEl = document.getElementById('secondary-sidenav');
            var hideSideNavButtonEl = document.getElementById('show-secondary-sidenav-button');
            // var showSideNavButtonEl = document.getElementById('hide-secondary-sidenav-button');
            var sidenavEl = document.getElementById('secondary-sidenav');
            this.sideNav = new Drawer(sidenavEl, {
                backdrop: true,
                bodyScrolling: true,
                onHide: function () {

                    //   showSideNavButtonEl.classList.remove('hidden');
                    hideSideNavButtonEl.classList.add('hidden');
                    barButtonEl.classList.add('hidden');
                },
                onShow: function () {

                    //  showSideNavButtonEl.classList.add('hidden');
                    hideSideNavButtonEl.classList.remove('hidden');

                    barButtonEl.classList.remove('hidden');
                }

            });
            this.sideNav.hide();

            hideSideNavButtonEl.addEventListener('click', function () {
                this.sideNav.hide();
            });






            //  showSideNavButtonEl.addEventListener('click', function () {
            //      this.sideNav.show();
            //  });


        },
       
        methods: {
            ...mapActions(["ChangeTenant", "LogOut", "ChangeLanguage"]),

            onClickSignOut() {
                this.LogOut();
                window.location = "/login"
            },
            onSelectMenu: function (item) {

                if (item == undefined) {
                    this.showDocumentationMenu = false;
                    this.currentitem = this.tenantMenu;
                    this.subitems = this.tenantMenu.children;
                    this.sideNav.show();
                    return;
                }
                this.showDocumentationMenu = true;
                this.currentitem = item;
                this.subitems = item.children;

                this.sideNav.show();
            },
            onSelectSubMenu: async function (item) {


                if (item.link == "changeTenant") {

                    let tenantRecord = await this.ChangeTenant(item.id);

                    if (tenantRecord) {
                        this.currentTenant = {
                            "_id": tenantRecord.id,
                            "name": tenantRecord.name,
                            "logo": this.host_media + tenantRecord.image_id + "?icon=true"

                        };
                        window.location = this.$route.path;
                        this.sideNav.hide();
                    }
 

                    return;
                }

 
                this.sideNav.hide();

                this.$router.push({ path: item.link, query: {   }}   )
            },
            getMediaIdentifier: function () {
                if (this.user == undefined) return;

                return this.user.tenant.media_id;
            },
            loadTenants: async function () {
                let tenants = await service.getData("me/tenants", this.user?.token, {});
                let t = [];

                if (tenants?.records == undefined)
                    this.tenants = []
                else
                    this.tenants = tenants?.records

                for (let i = 0; i < this.tenants.length; i++) {
                    if (this.tenants[i]._id == this.user.tenant_id) {

                        this.currentTenant = {
                            "_id": this.tenants[i].id,
                            "name": this.tenants[i].name,
                            "logo": this.host_media + this.tenants[i].image_id + "?icon=true"
                        }
                    }
                    t.push(
                        { "id": this.tenants[i]._id, "name": { "EN": this.tenants[i].name, "FR": this.tenants[i].name }, "link": "changeTenant" }
                    )
                }

                let item = { "id": "tenant", "name": { "EN": "Comptes Clients", "FR": "Comptes Clients" }, "children": t }
                this.tenantMenu = item;

            },
            loadMenu: async function () {
                if (this.user == undefined) return;

                //  let config = await service.getData("sidemenus", this.user?.token, Object.assign({}, { limit: 1, offset: 0 }, {}))

                //  console.log("SIDEMENU ------------------------------------------------------------")
                //  console.log(JSON.stringify(config))



                let config2 = await service.getSideMenus(this.user?.token)

                //console.log("SIDEMENU 2------------------------------------------------------------")
                //console.log(JSON.stringify(config2))
                if ((config2 != undefined)) {

                    this.config = config2
                    return;
                }

                this.config = { items: [] }

            }

        },
       
       
   }

</script>