<template>
  <div class="  mb-2 w-full min-h-96 relative">
    <label
      class="hidden flex flex-row font-bold text-left ml-4 px-2.5 text-center block mb-2 text-lg   text-gray-900 dark:text-white"
    >
      <div class="  w-full">{{ labels['LABEL_RECIPE_TITLE'][lg] }}</div>

    </label>

    <div>
      <div class="w-full flex  ">
        <div class="w-12/12 w-full border   ">
          <draggable
            ghost-class="ghost"
            class="mt-4 w-full   min-h-96 border-1 border-solid items-start flex flex-col bg-white mb-3 border-gray-300 p-6"
            class2="list-group mt-2 w-full h-12  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
            :list="lines"
            item-key="id"
            @start="drag=true"
            @end="drag=false"
          >
            <template #header>
              <div
                class="   mb-2 w-full py-2 flex justify-end"
                role="group"
              >
                <button
                  type="button"
                  class="  flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  @click="onOpenList"
                >
                  <i class="fa-solid fa-plus  mr-2" />
                  AJOUTER
                </button>
              </div>
            </template>
            <template #item="{element, index}">
              <div
                class="cursor-pointer list-group mt-2 w-full  bg-gray-50 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700   flex flex-row justify-center"
                :class="{'border-blue-600 border-3': (selectedItem == index), 'border-gray-2s00': (selectedItem != index)}"
                @click="onSelectItem(index)"
              >
                <div class="flex flex-col items-center w-full  pl-2">
                  <div class=" h-12 flex flex-row items-center w-full pr-6 py-3 mb-3 mt-2 ">
                    <div class="w-full flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 pr-2 text-sm"
                        viewBox="0 0 320 512"
                      ><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z"
                        />
                      </svg>
                      <label
                        class="w-full pr-3 text-left block  text-sm font-bold text-gray-900 dark:text-white"
                      >
                        {{ element.name }}
                      </label>
                      <label
                        class="w-full pr-3 text-left block  text-sm font-normal text-gray-500 truncate dark:text-white"
                      >
                        {{ element.description }}
                      </label>
                      <div class="w-48 ">
                        {{ element.workload }}
                      </div>
                    </div>
                    <div class="w-auto flex  w-48 items-center justify-end  ">
                      <div class="  w-auto flex  w-full   items-center justify-end   w-16 px-3">
                        <div
                          href="#"
                          class=" flex justify-end   cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg          focus:outline-none  "
                          @click="onClickEdit(element)"
                        >
                          <i class="fa-solid fa-pen  " />
                        </div>
                      </div>
                      <div class="  w-auto flex  w-full   items-center justify-end   w-16 px-3">
                        <div
                          href="#"
                          class=" flex justify-end   cursor-pointer text-gray-800 hover:text-gray-900   font-bold   text-lg          focus:outline-none  "
                          @click="onClickDelete(element)"
                        >
                          <i class="fa-solid fa-xmark  " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
  <SelectRecipeStepModal
    :item="item"
    @close="onSelectIngredientClose"
    @validate="onSelectIngredientSelect"
  />


  <div
    id="select-recipe-step-confirm-delete-modal"
    tabindex="-1"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button
          type="button"
          class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-hide="popup-modal"
          @click="onConfirmClose"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="p-4 md:p-5 text-center">
          <svg
            class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          /></svg>
          
          <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Etes-vous sure de supprimer cette étape ?
          </h3>
          <button
            data-modal-hide="popup-modal"
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
            @click="onConfirmDelete"
          >
            OUI
          </button>
          <button
            data-modal-hide="popup-modal"
            type="button"
            class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click="onConfirmClose"
          >
            No,
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


    import SelectRecipeStepModal from '@/components/modals/SelectRecipeStepModal.vue'
    import draggable from 'vuedraggable'
    //import Multiselect from '@vueform/multiselect'
    import labels from "@/assets/lg/content-composer.json"
    
    import { Modal } from 'flowbite';
    import { v4 } from 'uuid';


    export default {
        name: 'ContentRecipeComposerNew',
        components: {
            SelectRecipeStepModal, draggable,
        },
        props: {
            id: String,
            label: String,
            placeholder: String,
            required: Boolean,

            value: Object,
            parent: Object,

            config: Object
        },
        data() {
            return {
                output: [],
                reference: {
                    value: "10",
                    unit: "kg"
                },
                filtered: {},
                labels: labels,
                lg: 'FR',
                item_to_delete : null,
                drag: false,
                products: [],
                item: {},
                confirmModal: null,
                addIngredientModal: null,
              
           
                lines: [],
                mode: 'stock',
                enabled: true,
                selectedLang: 'fr',
                selectedItem: null,
                dragging: false
            }
        },
        computed: {

            draggingInfo() {
                return this.dragging ? "under drag" : "";
            }
        },
        watch: {


            parent: async function () {

                this.lines = this.parent;
            },

            lines: function () {

            },
            value: function (val) {

                this.lines = val;

            }
        },
        mounted() {

            this.lines = this.value;


            const modalEl = document.getElementById('select-recipe-step-modal');
            this.addIngredientModal = new Modal(modalEl, {
                placement: 'center', backdrop: "static", closable: false
            });

            const modalEl2 = document.getElementById('select-recipe-step-confirm-delete-modal');
            this.confirmModal = new Modal(modalEl2, {
                placement: 'center', backdrop: "static", closable: false
            });

        },

        methods: {

            onValueChanged: function (val) {

                this.lines[val.index].quantity = val.value;

            },

            onValueTotalChanged: function (val) {
                this.reference = val.value;

            },


            onSwitchChange: async function (id) {
                if (this.lines[this.selectedItem].products == undefined) this.lines[this.selectedItem].products = {}
                this.lines[this.selectedItem].products[id] = this.filtered[id]
            },
            updateSupplierProducts: async function () {



            },

            onSelectItem: async function () {
                // this.selectedItem = index
                // await this.updateSupplierProducts(this.lines[index].type_id)

            },



            getIndex: function (id) {


                let index = this.lines.findIndex(function (el) { return el.id == id })


                return index;
            },
            selectLanguage: function (lg) {
                this.selectedLang = lg;

            },

            getData() {


                return this.lines;


            },
            onSelectIngredientSelect: function (item) {
                alert(JSON.stringify(item.controls))

                if (this.lines == undefined) this.lines = []



                if (item.id == undefined) {
                    item.id = v4()
                    this.lines.push(item)
                }
                else {
                    let index = this.lines.findIndex(function (a) {
                        return a.id == item.id
                    })
                    if (index != -1)
                        this.lines[index] = item
                }




                this.addIngredientModal.hide();
            },
            onSelectIngredientClose: function () {
                this.addIngredientModal.hide();
            },
            onOpenList: function () {

                this.item =
                {
                    id: null,
                    name: "",
                    description: "",
                    duration: "",
                    workload: "",
                    controls: []
                }


                this.addIngredientModal.show();

            },
            selectValue: function (tag, unit) {
                //this.output = a


                let index = this.getIndex(tag)
                if (this.lines[index] == undefined) this.lines[index] = { unit: '' }
                this.lines[index].unit = unit;
 
            },
            onClickEdit: function (item) {



                this.item =
                {
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    duration: item.duration,
                    workload: item.workload,
                    controls: item.controls
                }


                this.addIngredientModal.show();


            },


            onConfirmClose: function () {
                this.confirmModal.hide()
                this.item_to_delete = null;
            },

            onConfirmDelete: function () {
          
                function removeItemOnce(arr, index) {

                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                    return arr;
                }

                for (let i = 0; i < this.lines.length; i++) {

                    if (this.lines[i].id == this.item_to_delete.id) {

                        //   this.lines = this.output;
                        this.lines = removeItemOnce(this.lines, i);
                        this.item_to_delete = null;
                        this.confirmModal.hide()
                        return
                    }
                }

                this.item_to_delete = null;
                this.confirmModal.hide()


            },
            onClickDelete: function (item) {

                this.item_to_delete = item;
                this.confirmModal.show()



            }
        }
    }
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    .buttons {
        margin-top: 35px;
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }
</style>