<template>
  <div class="mt-2 mb-2 w-full relative flex-col justify-center items-center">
    <div class="hidden ">
      data -{{ data }}-<br>


      record -{{ record }}-<br>

      source -{{ source }}-<br>
      imagePath -{{ imagePath }}-<br>

      config -{{ config }}-<br>
    </div>
    <label class="w-full text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">

      <span>
        <div class="font-normal text-gray-500 text-sm flex justify-start  w-full">
          {{ config?.label?.FR }}
        </div>
      </span>
    </label>

    <div class="p-3 mt-2 mb-2 w-full relative flex justify-center items-center">
      <img
        v-if="data == undefined && source!=undefined"
        :src="`${host_media}${imagePath}?icon=true`"
      >
      <img
        v-if="data != undefined && source==undefined"
        :src="`${host_media}${imagePath}?icon=true`"
      >
    </div>
  </div>
</template>

<script>


    import Handlebars from "handlebars";


    export default {
        name: 'ContentImage',
        props: {
            source: String,
            data: String,
            label: String,
            record: Object,
            config: Object
        },
        data() {
            return {
                output: ""
            }
        },

        computed:
        {
            imagePath: function () {
                const template = Handlebars.compile(this.source);

                let label = (template(this.record));
                if(label == undefined) return "666193547a0ca6357a1b0532"
                if(label == "") return "666193547a0ca6357a1b0532"
                
                return label
            }
        },
        watch: {
            value: function (val) {
                this.output = val;
            }
        },
        methods: {


        }
    }
</script>