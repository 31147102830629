<template>
  <div>
    <div
      v-if="isDeveloper != true"
      class=""
    >
      {{ value }}
    </div>
    <div
      v-if="isDeveloper == true"
      class=""
    >
      <div v-if="editedFieldId === id">
        <input
          :ref="`field${id}`"
          v-model="value"
          type="text"  
                
          class="w-full bg-gray-50     border-none  w-full"
          @keyup.prevent="toggleEdit($event,false)"
        >
      </div>
      <div v-else>
        <div class="flex items-center">
          <span>
            {{ value }}
          </span>
          <button
            class="text-sm ml-2 text-green-400"
            @click.prevent="toggleEdit($event,true)"
          >
            <i class="fas fa-edit" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    export default {
        name: 'ContentInlineEditor',
        props: {
            input: String,
            isDeveloper :  Boolean
        },
        data: function () {
            return {
                editedFieldId: null,
                id: 1,
                value: ""
                
            };
        },

        watch: {
            'input': function()
            {
                this.value = this.input
            }
        },
        methods:
        {
            toggleEdit: function (ev, flag ) {
                if (flag) {
                    this.editedFieldId = this.id;
                    this.$nextTick(() => {
                        if (this.$refs["field" + this.id]) {
                            this.$refs["field" + this.id].focus();
                        }
                    });
                } else {


                    if (event.key === "Enter") {
                        this.editedFieldId = null;
    }
                    
                }
            }
        }
    }
</script>

<style>
  
    .editable-field {
      margin: 10px 0;
    }
 
    .editable-field button {
  
      padding: 4px 6px;
    
    }
    </style>