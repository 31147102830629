<template>
  <div class="mb-4 w-full relative w-full   min-h-50 overflow-y-auto">
    <div class="w-full flex flex-row items-center  ">
      <div class="  hidden  w-full flex flex-col">
        <div class="   w-full  bg-blue-100">
          {{ localrecords }}
        </div>
        <div class="   w-full  bg-gray-100">
          - {{ records }} -
        </div>
        <div class="hidden w-full  bg-green-100">
          - {{ values }} -
        </div>
        <div class="  w-full bg-orange-100">
          - {{ items }} -
        </div>
        <div class="w-full hidden">
          {{ config }}
        </div>
        <div class="w-full bg-purple-100 ">
          - {{ columns }} -
        </div>
      </div>

      <label v-if="records != undefined && (configTable?.options?.show_total != false)" :for="`${id}`"
        class="py-2 w-full text-left block  text-sm font-bold text-gray-900 dark:text-white">
        Total : {{ getTotal }}
      </label>

      <div v-for="bt in configTable?.buttons" :key="bt.id">
        <button type="button"
          class="mb-2 flex items-center justify-left px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          @click="onClickButtonBar(bt)">
          {{ bt?.label && bt?.label[lg] }}
        </button>
      </div>

      <label :for="`${id}`" class="hidden w-full text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
        {{ label }}
      </label>
    </div>

    <div v-if="isButton" class="w-full inline-flex justify-start  items-center">
      <input :id="`${id}`" v-model="output" type="text"
        class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm   focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        :placeholder="placeholder" :required="required">

      <button type="button"
        class="  ml-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        @click="onClickExport">
        <i class="fa-solid fa-ok mr-2 " />
        {{ common_labels['BUTTON_CHANGE'][lg] }}
      </button>
    </div>

    <div class="overflow-x-auto">
      <div v-if="localrecords == undefined" class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        Information non disponible
      </div>
      <table v-if="(localrecords != undefined) "
        class="bg-white w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th v-for="col in columns" :key="col.id" scope="col" :class="col.styleh" class="px-4 py-3">
              {{ col.name[lg] }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row,index) in localrecords" :key="row.id" class="border-b dark:border-gray-700">





            <td v-for="col in columns" :key="col.id" class="  px-4 py-3">
             
              <div v-if="col.type != 'buttons' && col.type != 'input' && col.type != 'check'" class="w-full"
                :class="col.style" v-html="getValue(col, row)" />

              <div v-if="col.type=='check'" class="flex items-center">
                <input id="checkbox-all" type="checkbox"
                  class="w-4 h-4   border-gray-300 rounded text-primary-600 focus:ring-primary-500    focus:ring-2  ">
                <label for="checkbox-all" class="sr-only" />
                <div class="ml-2 w-full" :class="col.style" v-html="getValue(col, row)" />
              </div>


              <div v-if="col.type == 'buttons'" class="w-full flex items-center justify-center  ">
                <button v-for="button in col.buttons" :key="button.action" type="button"
                  :class="`text-${button.color} bg-${button.bgcolor} hover:bg-'+button.bgcolor+'-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`"
                  @click="onClickButton(button, row)">
                  <i :class="`fa-solid ${button.icon}`" />
                </button>
              </div>

              <div v-if="col.type == 'input' && col.format!='date'" class="w-full flex-col flex items-center justify-center  ">
                 
                <ContentInput :id="`content-text-input-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input-${id}-${row?._id}`" :config="buildItem(col, row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" :format="buildFormat(col,row)"
                  :dunit="buildUnit(col, row)" @update-field="configFieldChange" @value-changed="onValueChanged"
                  @manage-field="manageField" />
              </div>

              <div v-if="col.type == 'switch'" class="w-full flex items-center justify-center  ">
                <ContentSwitch :id="`content-text-input-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input-${id}-${row?._id}`" :config="buildItem(col,row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" :format="buildFormat(col,row)"
                  :dunit="buildUnit(col, row)" @update-field="configFieldChange" @value-changed="onValueChanged"
                  @manage-field="manageField" />
              </div>

              <div v-if="col.type == 'select'" class="w-full flex items-center justify-center  ">
                <ContentSelectCollection :ref="`tiny-content-selectCollection--${id}-${row?._id}-${col?.id}`"
                  :custom-action="customAction" :label="item.label && item.label[lg]" :datasource="col.datasource_id"
                  :mode="item.mode" :record="row" :filters="item.filters" :config="item" :value="row[item.data]"
                  :choices="samples" @manage-field="manageField" @value-changed="onValueChanged" />
              </div>



              <div v-if="col.type == 'input' && col.format=='date'" class="w-full flex  items-center justify-center  ">
                <ContentDate :id="`content-text-input-${id}-${row?._id}-${col?.id}`"
                  :ref="`content-text-input-${id}-${row?._id}`" :config="buildItem(col,row)" :index="index.toString()"
                  :label="item?.label && item.label[lg]" :is-button="item?.isButton"
                  :placeholder="item?.placeholder[lg]" :value="buildValue(col,row)" @update-field="configFieldChange"
                  @value-changed="onValueChanged" @manage-field="manageField" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import common_labels from "@/assets/lg/common.json"
  import Handlebars from "handlebars";
  import ContentSwitch from '@/components/content/ContentSwitch.vue'
  import ContentInput from '@/components/content/ContentInput.vue'
  import ContentDate from '@/components/content/ContentDate.vue'
  import ContentSelectCollection from '@/components/content/ContentSelectCollection.vue'
  export default {
    name: 'ContentTinyTable',
    components: { ContentInput, ContentDate, ContentSwitch, ContentSelectCollection },
    props: {
      id: String,
      label: String,
      model: String,
      required: Boolean,
      records: Object,
      values: Object,
      config: Object,
      configTable: Object
    },
    data() {
      return {
        output: "",
        items: [],
        localrecords: [],
        customAction: null,
        lg: "FR",
        common_labels: common_labels,
        columns: [],
        item:
        {
          "id": "c7f97ce9-8781-4248-9ce6-4d985ccf7179",
          "label": {
            "EN": null,
            "FR": null
          },
          "placeholder": {
            "EN": null,
            "FR": null
          },
          "type": "text",
          "format": "number",
          "data": "reference",
          "class": "w-6/12"
        }
      }
    },
    computed: {

      isButtonAdd: function () {
        return true
      },
      isButton: function () {
        return false
      },
      getTotal: function () {


        if (this.localrecords == undefined) return "";
        //if (this.model == 'product')
        //   return this.records.reduce((acc, curr) => acc + parseInt(curr.accepted), 0)

        return this.localrecords?.length
        //return this.records.reduce((acc, curr) => acc + parseInt(curr.quantity), 0)
      }
    },
    watch: {

      'configTable?.cols': function () {
        this.columns = this.configTable?.cols?.filter(function (item) { return item.type !== 'hidden' })


      },
      'configTable': function () {
        this.columns = this.configTable?.cols?.filter(function (item) { return item.type !== 'hidden' })


      },
      'records': function () {

        this.localrecords = this.records;
        this.refreshTable()
      },
      //  'values': function () {
      //   this.refreshTable()
      //  }

    },
    mounted() {

      Handlebars.registerHelper("dateFormat", function (options) {
        //return options.fn(this);

        if (options.fn(this) == undefined) return "-"
        if (options.fn(this) == "") return "-"
        if (options.fn(this) == "-") return "-"
        let d = new Date(options.fn(this))
        let m = ("0" + (d.getMonth() + 1)).slice(-2)
        let day = ("0" + (d.getDate())).slice(-2)


        return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear());
      });



      Handlebars.registerHelper("datetimeFormat", function (options) {
        //return options.fn(this);

        if (options.fn(this) == undefined) return ""
        if (options.fn(this) == "") return ""
        let d = new Date(options.fn(this))
        let m = ("0" + (d.getMonth() + 1)).slice(-2)
        let day = ("0" + (d.getDay() + 1)).slice(-2)
        let hour = ("0" + (d.getHours() + 1)).slice(-2)
        let minute = ("0" + (d.getMinutes() + 1)).slice(-2)

        return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear() + " à " + hour + ":" + minute);



      });

      //alert(JSON.stringify(this.configTable?.cols))
      this.columns = this.configTable?.cols?.filter(function (item) { return item.type !== 'hidden' })


      this.localrecords = this.records;
      this.refreshTable()

    },
    methods: {

      refreshTable() {

        if (this.values == undefined) {
          this.items = []
          return;
        }

        for (let i = 0; i < this.values?.length; i++) {

          let val = this.values[i]
          let index = this.localrecords.findIndex(function (a) { return a._id == val._id })

          if (index != -1) {
            console.log(">>>>>>> FIND")
            this.items.push(val)

            this.localrecords[index]['price_with_tax'] = val['price_with_tax']

          }
          else console.log(">>>>>>> NOT FIND")

          //price_with_tax
        }
      },



      buildItem: function (col,row) {

        //  console.log("buildItem", row, col)
        return {
          "id": row?._id + "c7f97ce9-8781-4248-9ce6-4d985ccf7179-" + col.id,
          "label": {
            "EN": null,
            "FR": null //row?._id + "c7f97ce9-8781-4248-9ce6-4d985ccf7179" + col.id
          },
          "placeholder": {
            "EN": null,
            "FR": null
          },
          "type": "text",

          "prefix": col.prefix,
          "suffix": col.suffix,

          "format": col.format , //? col.format : "mass",
          "data": col?.attribut,
          "class": "w-6/12"
        }
      },
      configFieldChange: function () {
        console.log("configFieldChange")
      },
      manageField: function () {
        console.log("manageField")
      },
      onValueChanged: function (item) {

        console.log("onValueChanged ------------------------------------------", item.id)
        //  console.log(JSON.stringify(item))


        // console.log(JSON.stringify(this.localrecords[item.index]))


        this.localrecords[item.index][item.id] = item.value
        let _id = this.localrecords[item.index]._id

        let itemIndex = this.items.findIndex(function (a) { return a._id == _id })
        console.log("itemIndex", itemIndex)


        if (itemIndex == -1) {

          console.log("INDEX NOT FOUND")


          let newItem2 = this.localrecords[item.index];
          //   newItem[item.id] = item.value;
          this.items.push(newItem2)

        }
        else {
          this.items[itemIndex][item.id] = item.value;
          console.log("INDEX  FOUND")
        }



        /*
        if (itemIndex == -1) {

          console.log(JSON.stringify(item))
          console.log(item.id, " - ", this.localrecords[item.index])
     
          // newitem[item.id] = item.value;

          let newitem = this.localrecords[item.index]
          console.log("New item --------------------")
          console.log(newitem)
          newitem.index = item.index
          // if ( newitem[ item.id])  
          newitem[item.id] = item.value
          // newitem[ item.id].value  = item.value

          this.items.push(newitem)
        }
        else {
          //                  console.log("+++++++-------------------------------------")
                 this.items[itemIndex][item.id] = item.value;


                 this.localrecords[item.index][item.id]= item.value;


          //                  console.log(JSON.stringify(this.items[itemIndex]))
          //                  console.log("+++++++-------------------------------------")
          //   this.items[itemIndex].quantity.value = item.value

          //   this.items[item.id].value =  item.value;
        }
          */

        //                console.log("-------------------------------------")
        //                console.log(JSON.stringify(this.items))
        //                console.log("-------------------------------------")
      },





      buildValue: function (col, data) {


        //   console.log("########### ", col.data, JSON.stringify(data))



        let value = this.getValue(col, data)
        //  console.log("########### value", value)
        // console.log(this.id, "buildValue", data.unit)
        return { value: value, unit: this.buildUnit(col, data), sign: null };
      },
      buildFormat: function (col, data) {
        // console.log(this.id, "buildFormat", data.unit)
        if (data == undefined) return
        if ((data.unit != undefined) && (col.unit != undefined)) { return "famous" }
        return null;
      },
      buildUnit: function (col  , data ) {
        if (data == undefined) return
        if (data.unit == "") data.unit = null


        if (col.unit == "rule1") {

          if (data.product_packaging_type == "raw") {
            return "kg"
          }

          if (data.product_packaging_type == "unit") {
            return "uvc"
          }


          if (data.product_packaging_type == "bundle") {
            return "pack"
          }

          if (data.unit != undefined) {
            return data.unit.unit
          }


        }

        return col.unit
      },
      buildUnitOld: function (col, data) {

        if (data == undefined) return
        if (data.unit == "") data.unit = null


        if (col.unit == "rule1") {

          if (data.product_packaging_type == "raw") {
            return "kg"
          }

          if (data.product_packaging_type == "unit") {
            return "uvc"
          }


          if (data.product_packaging_type == "bundle") {
            return "pack"
          }

          if (data.unit != undefined) {
            return data.unit.unit
          }


        }

        // console.log(this.id, "buildFormat", data.unit)

        if ((data.unit != undefined) && (col.unit != undefined)) {
          return data.unit.unit
        }


        return col.unit ? col.unit : null;
      },
      onClickButton: function (button, item) {
        //  console.log(item)


        let event =
        {
          id: this.id,
          data: item,
          action: button.action
        }


        this.$emit("manageButtonClick", event)

      },


      onClickButtonBar: function (button) {

        let event =
        {
          id: null,
          data: null,
          action: button.action
        }


        this.$emit("manageButtonClick", event)
      },
      getValue(col, record) {

        const template = Handlebars.compile(col.data);
        let value = (template(record));
        let displayValue = value;


        if (col?.map != undefined) {
          if (col?.map[this.lg] != undefined) {
            if (col?.map[this.lg][value] != undefined) {
              displayValue = col?.map[this.lg][value]
            }
          }
        }

        if (col.type == "text") return displayValue;
        if (col.type == "date") return displayValue;
        if (col.type == "date-time") return displayValue;
        if (col.type == "time") return displayValue;

        if (col.type == "badge") {

          let color = "gray"
          if (col?.colors != undefined)
            if (col?.colors[value] != undefined) {
              color = col?.colors[value]
            }
          let html = '<span class="bg-' + color + '-100 text-' + color + '-800 border border-' + color + '-400 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 ">' + displayValue + '</span>';
          return html;
        }

        return value
      },
      getData() {
        
        return this.items

        // return this.records
      }
    }
  }
</script>
<style>
  blockquote {
    background: #eee;
    border-radius: 5px;
    margin: 16px 0;
  }

  blockquote p {
    padding: 15px;
  }

  cite {
    margin: 16px 32px;
    font-weight: bold;
  }

  blockquote p::before {
    content: '\201C';
  }

  blockquote p::after {
    content: '\201D';
  }

  [contenteditable='true'] {
    caret-color: red;
  }
</style>