<template>
  <div
    id="chart-view-source-modal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
  >
    <div class="relative w-full h-full max-w-3xl p-4 md:h-auto ">
      <!-- Modal content -->
      <div class=" relative pt-4 pb-2 bg-gray-200 rounded-lg shadow dark:bg-gray-800  ">
        <!-- Modal header -->
        <div
          class="  px-4 flex items-center justify-between pb-4 mb-4 border-b border-gray-200 rounded-t sm:mb-5 dark:border-gray-700"
        >
          <h3 class="font-semibold text-gray-900 text-lg dark:text-white">
            {{ labels['TITLE_POSTAL_ADDRESS'][lg] }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex dark:hover:bg-gray-600 dark:hover:text-white"
            @click=" onClose()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="bg-white rid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5 p-4">
          <div class="w-full h-96 overflow-y-auto">
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              {{ labels['TITLE_POSTAL_ADDRESS_DESCRIPTION'][lg] }}
            </p>
                        
            <div
              v-for="group in collections"
              :key="group.id"
            >      
              <div
                class="flex justify-between items-center pt-3 pl-2 mt-5 mb-2 text-sm font-medium text-gray-500 uppercase border-t border-gray-200 dark:text-gray-400 dark:border-gray-700"
              >
                <h3>{{ group.name }}</h3>
                <a
                  href="#"
                  class="inline-flex text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
                >
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
                       
              <ul class="space-y-1.5">
                <li
                  v-for="item in sources.records"
                  :key="item._id"
                >
                  <a
                    href="#"
                    class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                      />
                    </svg>
                    <span class="ml-3">{{ item?.name?.FR }} **</span>
                  </a>
                </li> 
              </ul>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end px-4  ">
          <button
            type="button"
            class="mr-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            @click=" onClose()"
          >
            Annuler
          </button>
          <button
            type="button"
            class="inline-flex items-center text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-900"
            @click="onValidate"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import labels from "@/assets/lg/postal-address.json"


    export default {
        name: 'CreatePostalAddressModal',
        components: {

        },
        props: {
            input: String,
            sources: Array
        },
        data() {
            return ({
                lg: "FR",
                labels: labels,
               
                forThis: "",
                id: null,

                gs1_gln: "",

                transparent: false,
                item: {
                    id: "1" 
                },
                collections: []
            })
        },
        watch: {
            'input': function () {

                this.id = this.input?.id


                // this.name = this.input
            }
        },
        mounted() {
            this.id = this.input?.id





            this.collections = [


                {
                    id: "0",
                    name: "Gestion Fournisseur",
                    collection: []
                },


                {
                    id: "0",
                    name: "Gestion Catalogue Produit",
                    collection: []
                },

                {
                    id: "0",
                    name: "Gestion Commeriale",
                    collection: []
                },

                {
                    id: "0",
                    name: "Portail B4Food",
                    collection: []
                },

                {
                    id: "0",
                    name: "Gestion Production",
                    collection: []
                }

            ]



        },

        methods: {
            onValidate() {

                let item =
                {
                    id: this.id,

                }


                this.$emit("confirm", item)

                // this.name = ""
            },

            onClose() {
                this.$emit("close")
                //  this.name = ""
            }
        },
    }
</script>