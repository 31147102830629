<script>
  import blank from "@/assets/img/blank.png"

  import Handlebars from "handlebars";


  export default {
    name: 'ContentDisplay',
    props: {
      id: String,
      label: String,
      type: String,
      value: {},
      combined: Object,
      col: Object,
      record: Object,
      blank: blank,
      config: Object
    },
    data() {
      return {
        lg: "FR",
        output: ""
      }
    },
    computed:
    {
      isTitle: function () {
        if (this.config?.format == 'badge') return false
        if (this.config?.format == 'separator') return false
        if (this.config?.template == 'image-title-subtitle-2') return false
        return true
      }
    },
    watch: {
      value: function (val) {
        this.output = val;
      }
    },
    mounted() {
      this.output = this.value;
    },
    methods: {

      badgeClass(v, col) {
        let o = ""
        if (v == '') v = 'default'
        if (v == '') v = 'default'
        if (col.text_class) o = ' ' + col.text_class

        if (col['colors'] == undefined) return 'bg-gray-100 text-gray-800 border-gray-400' + o;
        if (col['colors'][v.toString()] == 'purple') return 'font-bold bg-purple-100 text-purple-800 border-purple-400' + o;


        if (col['colors'][v.toString()] == 'red') return 'font-bold bg-red-100 text-red-800 border-red-400' + o;
        if (col['colors'][v.toString()] == 'orange') return 'font-bold bg-orange-100 text-orange-800 border-orange-400' + o;
        if (col['colors'][v.toString()] == 'green') return 'font-bold bg-green-100 text-green-800 border-green-400' + o;
        return 'font-bold bg-gray-100 text-gray-800 border-gray-400' + o + JSON.stringify(col['colors']);

      },
      displayURL(att) {
        if (this.col[att] == undefined) return ""

        const template = Handlebars.compile(this.col[att]);
        let label = (template(this.record));

        if (this.col.suffix != undefined) label += this.col.suffix
        if (this.col.prefix != undefined) label = this.col.prefix + label

        return label

      },

      displayValueImage(att) {


        //  console.log("displayValue2 -------------------", att)
        if (this.col[att] == undefined) return null

        //    console.log("this.col[att] -------------------", this.col[att])



        const template = Handlebars.compile(this.col[att]);
        let label = (template(this.record));
        //  console.log("label -------------------", label)

        if (label.toString() == "null") return "65b27bf72e56813ad06c6213"
        if (label.toString() == "") return "65b27bf72e56813ad06c6213"
        return label.toString();


      },



      displayValue2(att, map = true) {


        //  console.log("displayValue2 -------------------", att)
        if (this.col[att] == undefined) return null

        //    console.log("this.col[att] -------------------", this.col[att])

        Handlebars.registerHelper("dateFormat", function (options) {
          //return options.fn(this);

          if (options.fn(this) == undefined) return "-"
          if (options.fn(this) == "") return "-"
          if (options.fn(this) == "-") return "-"
          let d = new Date(options.fn(this))
          let m = ("0" + (d.getMonth() + 1)).slice(-2)
          let day = ("0" + (d.getDate())).slice(-2)


          return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear());
        });



        Handlebars.registerHelper("datetimeFormat", function (options) {
          //return options.fn(this);

          if (options.fn(this) == undefined) return ""
          if (options.fn(this) == "") return ""
          let d = new Date(options.fn(this))
          let m = ("0" + (d.getMonth() + 1)).slice(-2)
          let day = ("0" + (d.getDay() + 1)).slice(-2)
          let hour = ("0" + (d.getHours() + 1)).slice(-2)
          let minute = ("0" + (d.getMinutes() + 1)).slice(-2)

          return new Handlebars.SafeString(day + "/" + m + "/" + d.getFullYear() + " à " + hour + ":" + minute);
        });

        const template = Handlebars.compile(this.col[att]);
        let label = (template(this.record));
        //  console.log("label -------------------", label)

        if (this.col.suffix != undefined) label += this.col.suffix
        if (this.col.prefix != undefined) label = this.col.prefix + label

        if (this.col.values != undefined) {
          let t = this.col.values.find(function (a) { return a.value == label })
          if (t != undefined)
            label = t.title
        }
        if (label == "C") label = "CONFORME"
        if (label == "NC") label = "NON CONFORME"
        if (label == "") label = "-"

        if (map == false) return label.toString();

        if (this.col.map == undefined) return label.toString();
        if (this.col.map[this.lg] == undefined) return label.toString();
        if (this.col.map[this.lg][label] == undefined) return label.toString();
        //  return col.map[this.lg][label].toString();

        return this.col.map[this.lg][label]

      },

      displayHandlebar(record, col, map, att) {

        if (col[att] == undefined) return "";
        const template = Handlebars.compile(col[att]);
        let label = (template(record));
        if (label == "C") label = "CONFORME"
        if (label == "NC") label = "NON CONFORME"
        return label;
      },

      displayValueDebug(value, type, suffix) {

        console.log("displayValue -------------------------------------")
        console.log("value = ", value)
        console.log("type = ", type)
        console.log("suffix = ", suffix)
        console.log("displayValue -------------------------------------")
        console.log()

      },

      displayValue(value, type, suffix) {


        if (type == "date-time") {

          if (value == undefined) return "";
          let myDate = new Date(value);
          const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
          let date = myDate.toLocaleString('fr-FR', options);
          return date
          //return  date[0].replace("T"," ")
        }

        if (type == "date") {
          if (value == undefined) return "";
          let myDate = new Date(value);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          let date = myDate.toLocaleString('fr-FR', options);
          return date
          /*
                              let date = value.split("T")
                              if (date.length != 2) {
                                  date = value.split(" ")
                              }
                              return date[0];*/
        }
        if (suffix != undefined)
          return value + suffix;

        return value;
      },

      manageVariable(v, d, v_d) {

        if (Array.isArray(v)) {
          let r = ""
          for (let i = 0; i < v.length; i++) {
            r = r + this.manageVariableSub(v[i], d)
          }
          if (r == undefined) return v_d;
          return r;
        }
        let val = this.manageVariableSub(v, d)
        if (val == undefined)
          if (v_d != undefined) return v_d
          else ""
        return val
      },
      manageVariableSub(v /*, d*/) {

        if (v == undefined) {
          return v;
        }
        if (v[0] == '@') {
          //   if (d == undefined)  {}
          let a = v.replace('@', '')
          try {

            let p = 'd.' + a;

            let e = eval(p);

            if (e == undefined) e = ""
            return e;
          }
          catch (e) {

            return '-'
          }
        }
        return v
      }

    }
  }
</script>



<template>
  <div class="mt-2 mb-2 w-full flex flex-wrap relative  ">
    <label v-if="isTitle"
      class="  w-6/12 flex items-center text-left block mb-2 text-sm font-bold text-gray-900 dark:text-white">
      {{ label }}

    </label>
    <label v-if="type=='badge'" class=" text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      :class="col.textclass">

      <span class="    font-medium mr-2 px-2.5 py-0.5 rounded    border  "
        :class="badgeClass(displayValue2('data',false ), col)">

        {{ displayValue2('data' ) }}

      </span>

    </label>
    <label v-if="type=='text'" class="w-6/12 text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ displayValue2('data' ) }}
      <span>
        <div class="font-normal text-gray-500 text-sm flex justify-start  w-full">
          {{ displayValue2('subdata') }}
        </div>
      </span>
    </label>
    <label v-if="type=='date'" class="w-6/12 text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ displayValue2( 'data') }}
      <div class="font-normal text-gray-500 text-sm flex justify-start  w-full">
        {{ displayValue2('subdata') }}
      </div>
    </label>
    <label v-if="type=='date-time'"
      class="w-6/12 text-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ displayValue2('data' ) }}
      <div class="font-normal text-gray-500 text-sm flex justify-start  w-full">
        {{ displayValue2('subdata') }}
      </div>
    </label>

    <div v-if="type=='separator'" class=" w-full border-solid border-b   border-gray-300 " />


    <div v-if="type=='image-title-subtitle' && config?.template != 'image-title-subtitle-2' "
      class=" w-6/12 flex items-center  ">
      <div class="flex flex-row items-center justify-start w-full">
        <img :class=" displayValue2('image_class') " :src="`${host_media + displayValueImage('image_url')}?icon=true`">

        <div class="pl-3 justify-start">
          <div class="text-base font-semibold text-lg flex justify-start w-full" v-html="displayValue2('data')" />
          <div class="font-normal text-gray-500 text-sm flex justify-start  w-full">
            {{ displayValue2('subdata') }}
          </div>
        </div>
      </div>
    </div>



    <div v-if="type=='image-title-subtitle' && config?.template == 'image-title-subtitle-2' "
      class=" w-full flex items-center  ">
      <div class="flex flex-row items-center justify-start w-full">
        <img :class=" displayValue2('image_class') " :src="`${host_media + displayValueImage('image_url')}?icon=true`">

        <div class="pl-3 justify-start">
          <div class="text-base font-semibold text-lg flex justify-start w-full" v-html="displayValue2('data')" />
          <div class="font-semibold text-gray-500 text-sm flex justify-start  w-full">
            {{ displayValue2('subdata') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>