class FormValidation {

    constructor(data, rules) {
        this.data = data;
        this.rules = rules;
    }

    manageOR(rule, data) {
        let reponse = false;
        for (let i = 0; i < rule.length; i++) {

            let r = this.manageRule('or', rule[i], data)
            reponse = (r || reponse)

        }
        return reponse;
    }

    manageAND(rule, data) {
        let reponse = true;
        for (let i = 0; i < rule.length; i++) {
            let r = this.manageRule('and', rule[i], data)
            reponse = (r && reponse)
        }
        return reponse;
    }

    manageGTE(attr, rule, data) {
        return (data[attr] >= rule)
    }

    manageGT(attr, rule, data) {
        return (data[attr] > rule)
    }

    manageLT(attr, rule, data) {
        return (data[attr] < rule)
    }

    manageLTE(attr, rule, data) {
        return (data[attr] <= rule)
    }

    manageNOT(attr, rule, data) {
        return !this.manageRule(attr, rule, data)
    }

    manageNE(attr, rule, data) {
        return (data[attr] != rule)
    }

    manageIN(attr, rule, data) {
        return (rule.includes(data[attr]))
    }


    manageRule(current, rule, data) {
        let reponse = true
        let keys = Object.keys(rule)
        for (let i = 0; i < keys.length; i++) {
            let attr = keys[i].trim()
            switch (attr) {
                case '$or': reponse = (this.manageOR(rule[attr], data) && reponse); break;
                case '$and': reponse = (this.manageAND(rule[attr], data) && reponse); break;
                case '$gte': reponse = (this.manageGTE(current, rule[attr], data) && reponse); break;
                case '$gt': reponse = (this.manageGT(current, rule[attr], data) && reponse); break;
                case '$lte': reponse = (this.manageLTE(current, rule[attr], data) && reponse); break;
                case '$lt': reponse = (this.manageLT(current, rule[attr], data) && reponse); break;
                case '$not': reponse = (this.manageNOT(current, rule[attr], data) && reponse); break;
                case '$in': reponse = (this.manageIN(current, rule[attr], data) && reponse); break;
                case '$ne': reponse = (this.manageNE(current, rule[attr], data) && reponse); break;
                default:
                    {
                        if (typeof rule[attr] == "object") {
                            reponse = this.manageRule(attr, rule[attr], data);
                        }
                        else {
                            if (data[attr] === rule[attr]) {
                                reponse = reponse && true;
                            }
                            else {
                                reponse = reponse && false;
                            }
                        }
                    }
            }
        }
        return reponse
    }


    evaluateData() {
        let response = false;
        for (const rule of this.rules) {
            response = response || this.manageRule('root', rule, this.data)
        }
        return response;
    }

}

class FormValidationEngine {

    constructor(data, rules) {
        this.data = data;
        this.rules = rules;
        console.log("()",this.rules)
    }

    check() {
        let results = []
        if (this.rules == undefined) return true;

        console.log("check()",this.rules)
        if (!Array.isArray(this.rules))
        {
            alert(JSON.stringify(this.rules))
            return false
        }


   

        for (let i = 0; i < this.rules.length; i++) {
            const rule = new FormValidation(this.data, this.rules[i].rules);
            const result = rule.evaluateData();

            if (result == false) {
                results.push({ name: this.rules[i].name, message: this.rules[i].message, id: null })

            }

        }
        return results
    }
}

export default FormValidationEngine
