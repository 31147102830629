<template>
  <section class="bg-gray-50 dark:bg-gray-900 pb-3  ">
    <div class="px-4 mx-auto max-w-screen-2xl lg:px-4">
      <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        <div
          class="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
          <div class="flex items-center flex-1 space-x-4">
            <h5>
              <span class="ml-1 dark:text-white font-bold"> Score Conformité </span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  // <diagram :model="model" class="w-full" height="700" width="1400"></diagram>
  // import { Diagram } from 'vue-diagrams';
  // import    'vue-diagrams/dist/style.css'
  export default {
    name: "DiagramComponent",
    components: {
      //  Diagram
    },
    data() {
      //  const diagramModel = new Diagram.Model();

      //  const node1 = diagramModel.addNode("test2", 300, 200);
      //  const inPort = node1.addInPort("test");

      //  const node2 = diagramModel.addNode("test", 10, 300, 244, 80,{
      //resizable: true,
      //});
      //  const node2OutPort = node2.addOutPort("testOut");
      //  node2.addOutPort("testOut2");
      //  node2.color = "#00cc66";

      //  const node3 = diagramModel.addNode("test3", 10, 100, 72, 100);
      //  const node3OutPort = node3.addOutPort("testOut3");
      //  node3.color = "#cc6600";

      //  diagramModel.addLink(node2OutPort, inPort);
      //  diagramModel.addLink(node3OutPort, inPort);

      return {
        //  model: diagramModel
      };
    },
  };
</script>